import React, { useEffect, useState } from "react";
import {
  Button,
  Categories,
  Head,
  Loading,
  LocationPopup,
  Newsletter,
} from "../../Component";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchGetAllCategories } from "../../Redux/Category/action";
import { scrollToTop } from "../../Component/NavigateTop";
import { fetchGetCampaign } from "../../Redux/Campaign/action";
import Questions from "./Popup/Questions";

const Campaign = () => {
  const [showLocation, setShowLocation] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const [joinQuiz, setJoinQuiz] = useState(false);

  const data = localStorage.getItem("search-location");
  const parsedLocationData = JSON.parse(data);

  const [latitude, setLatitude] = useState(
    parsedLocationData ? parsedLocationData.latitude : ""
  );
  const [longitude, setLongitude] = useState(
    parsedLocationData ? parsedLocationData.longitude : ""
  );
  const [location, setLocation] = useState(
    parsedLocationData ? parsedLocationData.location : ""
  );
  const [keyword, setKeyword] = useState("");

  const catLoading = useSelector(
    (state) => state.categories.isloading,
    shallowEqual
  );

  const loading = useSelector(
    (state) => state.campaign.isloading,
    shallowEqual
  );
  const campaign = useSelector(
    (state) => state.campaign.campaign,
    shallowEqual
  );

  const msg = useSelector((state) => state.campaign.msg, shallowEqual);

  const message = useSelector((state) => state.campaign.message, shallowEqual);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClickSearch = () => {
    if (latitude && longitude) {
      const filter = [
        {
          label: "latitudes[]",
          value: latitude,
        },
        {
          label: "longitudes[]",
          value: longitude,
        },
        {
          label: "location",
          value: location,
        },
      ];
      localStorage.setItem("search-data", JSON.stringify(filter));
      navigate(`/search-result?genre=all&type=offering&key=${keyword}`);
    } else {
      navigate(`/search-result?genre=all&type=offering&key=${keyword}`);
    }
  };

  useEffect(() => {
    const filter = [
      {
        label: "latitudes[]",
        value: latitude,
      },
      {
        label: "longitudes[]",
        value: longitude,
      },
      {
        label: "location",
        value: location,
      },
    ];
    localStorage.setItem("search-data", JSON.stringify(filter));
  }, [latitude, longitude, location]);

  useEffect(() => {
    message && setJoinQuiz(false);
  }, [message]);

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetAllCategories());
    dispatch(fetchGetCampaign());
  }, []);

  if (catLoading || loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="pt-16 md:pt-24 relative">
      {showLocation || showCategories || joinQuiz ? (
        <div
          className="absolute w-full h-full bg-black opacity-10 z-30"
          onClick={() => {
            setShowLocation(false);
            setShowCategories(false);
            setJoinQuiz(false);
          }}
        ></div>
      ) : null}

      {showLocation && (
        <div className="w-full absolute top-28">
          <LocationPopup
            setShowLocation={setShowLocation}
            setLatitude={setLatitude}
            setLongitude={setLongitude}
            setLocation={setLocation}
            location={location}
          />
        </div>
      )}

      {joinQuiz && <Questions />}
      <Categories showCategories={showCategories} />

      <Head
        setShowCategories={setShowCategories}
        color="#2E8B4A"
        showLocation={showLocation}
        setShowLocation={setShowLocation}
        location={location}
        setKeyword={setKeyword}
        handleClickSearch={handleClickSearch}
      />
      <hr className="w-full mt-5" />

      {msg ? (
        <h6 className="text-center mt-10 font-medium text-2xl">{msg}</h6>
      ) : (
        <div className="11/12 xl:w-3/4 mx-auto mt-16">
          <h5 className="text-center text-xl md:text-2xl lg:text-3xl font-semibold px-3 md:px-0">
            {campaign.name}
          </h5>
          <h6 className="text-center text-base md:text-lg lg:text-xl font-medium mt-5 px-3 md:px-0">
            {campaign.subtitle}
          </h6>

          <div className="w-3/4 mx-auto border rounded-xl shadow-md border-b-0 p-5 mt-10 flex items-center justify-center flex-col">
            <p className="text-center text-xl text-gray-600">
              Boost Your Brain With{" "}
              <span className="medium text-black">Participating Quiz</span>
            </p>

            {message ? (
              <p className="text-base text-primary font-medium mt-2">
                {message}
              </p>
            ) : (
              <Button
                value="Join Quiz Now"
                handleClick={() => setJoinQuiz(true)}
                className="bg-primary rounded-full w-fit px-5 py-3 text-white mt-7"
              />
            )}
          </div>
        </div>
      )}

      <div className="w-full mt-10">
        <Newsletter
          color="#2E8B4A"
          bgColor="rgb(46 139 74 / var(--tw-bg-opacity))"
        />
      </div>
    </div>
  );
};

export default Campaign;
