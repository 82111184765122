import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  message: "",
  errMsg: "",
  inviteLink: "",
  qrCode: "",
  referral: [],
};

export const Referral = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_INVITE_LINKS:
      return {
        ...state,
        isloading: false,
        success: true,
        inviteLink: action.payload.invite_link,
        qrCode: action.payload.qrcode,
      };

    case ActionTypes.GET_REFERRAL_LOGIN:
      return {
        ...state,
        isloading: false,
        success: true,
        referral: action.payload,
      };

    case ActionTypes.REMOVE_REFERRAL_DATA:
      return {
        isloading: true,
        success: false,
        message: "",
        errMsg: "",
        inviteLink: "",
        qrCode: "",
        referral: [],
      };

    default:
      return state;
  }
};
