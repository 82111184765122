import React from "react";
import {
  Breadcrum,
  Button,
  Categories,
  FormikControl,
  Head,
  Loading,
  LocationPopup,
  Newsletter,
} from "../../Component";
import { useState } from "react";
import { useEffect } from "react";
import { scrollToTop } from "../../Component/NavigateTop";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchGetClassisupplierRequest,
  fetchSendClassiSupplierRequest,
  removeRequestData,
} from "../../Redux/Request/action";
import { fetchGetAllCategories } from "../../Redux/Category/action";
import TextError from "../../Component/Formik/Error";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ClassiSupplierRequest = () => {
  const [showCategories, setShowCategories] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showLocation, setShowLocation] = useState(false);

  const data = localStorage.getItem("search-location");
  const parsedLocationData = JSON.parse(data);

  const [latitude, setLatitude] = useState(
    parsedLocationData ? parsedLocationData.latitude : ""
  );
  const [longitude, setLongitude] = useState(
    parsedLocationData ? parsedLocationData.longitude : ""
  );
  const [location, setLocation] = useState(
    parsedLocationData ? parsedLocationData.location : ""
  );
  const [keyword, setKeyword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const categoryLoading = useSelector(
    (state) => state.categories.isloading,
    shallowEqual
  );
  const loading = useSelector((state) => state.request.isloading, shallowEqual);
  const countries = useSelector(
    (state) => state.request.countries,
    shallowEqual
  );
  const cities = useSelector((state) => state.request.cities, shallowEqual);
  const categories = useSelector(
    (state) => state.request.categories,
    shallowEqual
  );

  const message = useSelector((state) => state.request.message, shallowEqual);
  const errMessage = useSelector((state) => state.request.errMsg, shallowEqual);

  const handleClickSearch = () => {
    if (latitude && longitude) {
      const filter = [
        {
          label: "latitudes[]",
          value: latitude,
        },
        {
          label: "longitudes[]",
          value: longitude,
        },
        {
          label: "location",
          value: location,
        },
      ];
      localStorage.setItem("search-data", JSON.stringify(filter));
      navigate(`/search-result?genre=all&type=offering&key=${keyword}`);
    } else {
      navigate(`/search-result?genre=all&type=offering&key=${keyword}`);
    }
  };

  const initialValues = {
    company_name: "",
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    msg: "",
    postcode: "",
    website: "",
    city: "",
    country: "",
    address: "",
    abn: "",
    category: "",
    password: "",
    confirmPassword: "",
  };

  // check formik validation
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Required*"),
    company_name: Yup.string().required("Required*"),
    first_name: Yup.string().required("Required*"),
    last_name: Yup.string().required("Required*"),
    msg: Yup.string().required("Required*"),
    phone: Yup.string()
      .matches(/^[0-9]+$/, "Phone number must only contain digits")
      .min(9, "Phone number is too short")
      .max(11, "Phone number is too long")
      .required("Required*"),
    postcode: Yup.string().required("Required*"),
    website: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter correct url!"
      )
      .required("Please enter website"),
    city: Yup.string().required("Required*"),
    country: Yup.string().required("Required*"),
    address: Yup.string().required("Required*"),
    abn: Yup.string().required("Required*"),
    category: Yup.string().required("Required*"),
    password: Yup.string().required("Required*"),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    scrollToTop();
    dispatch(fetchSendClassiSupplierRequest(values));
    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
      onSubmitProps.resetForm({ value: "" });
    }, 1000);
  };

  useEffect(() => {
    message && toast.success(message);
    message && dispatch(removeRequestData());
    setTimeout(() => {
      message && dispatch(fetchGetClassisupplierRequest());
    }, 100);
  }, [message]);

  useEffect(() => {
    errMessage && toast.error(errMessage);
    errMessage && dispatch(removeRequestData());
    setTimeout(() => {
      errMessage && dispatch(fetchGetClassisupplierRequest());
    }, 100);
  }, [errMessage]);

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetAllCategories());
    dispatch(fetchGetClassisupplierRequest());
    return () => {
      dispatch(removeRequestData());
    };
  }, []);

  if (loading || categoryLoading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="pt-16 md:pt-24 relative">
      {showCategories || showLocation ? (
        <div
          className="absolute w-full h-full bg-black opacity-10 z-30"
          onClick={() => {
            setShowCategories(false);
            setShowLocation(false);
          }}
        ></div>
      ) : null}

      {showLocation && (
        <div className="w-full absolute top-28">
          <LocationPopup
            setShowLocation={setShowLocation}
            setLatitude={setLatitude}
            setLongitude={setLongitude}
            setLocation={setLocation}
            location={location}
          />
        </div>
      )}

      <Head
        color="#2E8B4A"
        setShowCategories={setShowCategories}
        showLocation={showLocation}
        setShowLocation={setShowLocation}
        location={location}
        setKeyword={setKeyword}
        handleClickSearch={handleClickSearch}
      />

      <hr className="w-full mt-5" />
      <div className="w-full bg-slate-100 py-10">
        <div className="w-11/12 xl:w-3/4 mx-auto">
          <Categories showCategories={showCategories} />
          <Breadcrum title="Home" subTitle="Classisupplier Request" />

          <h6 className="font-semibold text-2xl mt-8">
            Classisupplier Request
          </h6>

          <div className="w-full mt-12 grid grid-cols-12 gap-x-5 h-full">
            <div className="col-span-12 md:col-span-8 w-full h-full border rounded-xl bg-white p-7">
              <h6 className="font-semibold text-xl">Company Details</h6>
              <div className="mt-8">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                  validateOnMount
                >
                  {(formik) => (
                    <Form>
                      <div className="w-full flex flex-wrap lg:flex-nowrap items-center gap-x-5">
                        <div className="w-full">
                          <FormikControl
                            label="Company Name"
                            control="input"
                            name="company_name"
                            placeholder="Your company name"
                          />
                        </div>

                        <div className="w-full mt-5 lg:mt-0">
                          <FormikControl
                            label="Your Website"
                            control="input"
                            name="website"
                            placeholder="Your website url"
                          />
                        </div>
                      </div>

                      <div className="w-full mt-5 flex items-center gap-x-5 flex-wrap lg:flex-nowrap">
                        <div className="w-full">
                          <FormikControl
                            label="First Name"
                            control="input"
                            name="first_name"
                            placeholder="Your first name"
                          />
                        </div>

                        <div className="w-full mt-5 lg:mt-0">
                          <FormikControl
                            label="Last Name"
                            control="input"
                            name="last_name"
                            placeholder="Your last name"
                          />
                        </div>
                      </div>

                      <div className="w-full mt-5">
                        <FormikControl
                          label="Email"
                          control="input"
                          name="email"
                          placeholder="Your email address"
                        />
                      </div>

                      <div className="w-full mt-5">
                        <FormikControl
                          label="Phone Number"
                          control="input"
                          name="phone"
                          placeholder="Phone number"
                        />
                      </div>

                      <div className="w-full mt-5 flex items-center gap-x-5 flex-wrap lg:flex-nowrap">
                        <div className="text-sm w-full">
                          <label
                            htmlFor="country"
                            className="pb-2 font-medium flex text-textPrimary text-sm items-center justify-between"
                          >
                            Country
                            <ErrorMessage
                              name="country"
                              component={TextError}
                            />
                          </label>

                          <Field
                            as="select"
                            name="country"
                            className="w-full px-2 py-3 border rounded-lg outline-gray-300 text-secondary"
                          >
                            <option value="" selected disabled>
                              Choose a country
                            </option>
                            {countries &&
                              countries.map((val) => {
                                return (
                                  <option
                                    key={val.country_id}
                                    value={val.country_id}
                                  >
                                    {val.country_name}
                                  </option>
                                );
                              })}
                          </Field>
                        </div>

                        <div className="w-full text-sm mt-5 lg:mt-0">
                          <label
                            htmlFor="city"
                            className="pb-2 font-medium flex text-textPrimary text-sm items-center justify-between"
                          >
                            City
                            <ErrorMessage name="city" component={TextError} />
                          </label>

                          <Field
                            as="select"
                            name="city"
                            className="w-full px-2 py-3 border rounded-lg outline-gray-300 text-secondary"
                          >
                            <option value="" selected disabled>
                              Choose a City
                            </option>
                            {cities &&
                              cities.map((val) => {
                                return (
                                  <option key={val.id} value={val.citycode}>
                                    {val.cityName}
                                  </option>
                                );
                              })}
                          </Field>
                        </div>
                      </div>

                      <div className="w-full mt-5 flex items-center gap-x-5 flex-wrap lg:flex-nowrap">
                        <div className="w-full">
                          <FormikControl
                            label="Postal Code"
                            control="input"
                            name="postcode"
                          />
                        </div>

                        <div className="w-full mt-5 lg:mt-0">
                          <FormikControl
                            label="Street Address"
                            control="input"
                            name="address"
                          />
                        </div>
                      </div>

                      <div className="w-full mt-5 flex items-center gap-x-5 flex-wrap lg:flex-nowrap">
                        <div className="w-full">
                          <FormikControl
                            label="ABN"
                            control="input"
                            name="abn"
                          />
                        </div>

                        <div className="w-full text-sm mt-5 lg:mt-0">
                          <label
                            htmlFor="category"
                            className="pb-2 font-medium flex text-textPrimary text-sm items-center justify-between"
                          >
                            Category
                            <ErrorMessage
                              name="category"
                              component={TextError}
                            />
                          </label>

                          <Field
                            as="select"
                            name="category"
                            className="w-full px-2 py-3 border rounded-lg outline-gray-300 text-secondary"
                          >
                            <option value="" selected disabled>
                              Choose a Category
                            </option>
                            {categories &&
                              categories.map((val) => {
                                return (
                                  <option key={val.catid} value={val.catid}>
                                    {val.catname}
                                  </option>
                                );
                              })}
                          </Field>
                        </div>
                      </div>

                      <div className="w-full mt-5">
                        <FormikControl
                          label="Company Description"
                          name="msg"
                          control="textarea"
                          placeholder="Tell us a bit about your business"
                        />
                      </div>

                      <hr className="w-full mt-5" />
                      <p className="mt-5">Login Credentials</p>
                      <div className="w-full flex flex-wrap lg:flex-nowrap items-center gap-x-5 mt-5">
                        <div className="w-full">
                          <FormikControl
                            label="Password"
                            control="password"
                            name="password"
                            placeholder="Your Password"
                            type={`${showPassword ? "text" : "password"}`}
                            setShowPassword={setShowPassword}
                            showPassword={showPassword}
                          />
                        </div>
                        <div className="w-full mt-5 lg:mt-0">
                          <FormikControl
                            label="Confirm Password"
                            control="password"
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            type={`${
                              showConfirmPassword ? "text" : "password"
                            }`}
                            setShowPassword={setShowConfirmPassword}
                            showPassword={showConfirmPassword}
                          />
                        </div>
                      </div>

                      <Button
                        value="Submit"
                        className="w-full bg-primary text-white mt-7 rounded-xl py-3"
                      />
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="mt-10 md:mt-0 col-start-1 md:col-start-9 col-end-13 w-full h-fit border rounded-xl bg-white">
              <h6 className="font-semibold text-xl px-7 py-5">Need Help?</h6>
              <hr />
              <div className="w-full p-7">
                <Button
                  value="Contact Us Online"
                  handleClick={() => navigate("/contact")}
                  className="w-full bg-primary md:text-sm lg:text-base text-base text-white py-3 rounded-xl"
                />

                <Button
                  value="How It Works"
                  handleClick={() => navigate("/how-it-works")}
                  className="w-full border border-primary md:text-sm lg:text-base text-base text-primary mt-5 py-3 rounded-xl"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Newsletter
        color="#2E8B4A"
        bgColor="rgb(46 139 74 / var(--tw-bg-opacity))"
      />
    </div>
  );
};

export default ClassiSupplierRequest;
