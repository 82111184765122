import React from "react";
import { BsFillArrowUpRightCircleFill } from "react-icons/bs";

const Title = ({ more, color, title1, title2, para, handleClick }) => {
  return (
    <div className="w-full">
      <div className="w-full flex items-center gap-x-4 ">
        <div
          className="w-4 h-10 rounded-md border-2 "
          style={{ backgroundColor: color, borderColor: color }}
        ></div>
        <p className="font-semibold text-base" style={{ color: color }}>
          {" "}
          {title1}
        </p>
      </div>

      <div className="w-full flex items-center justify-between md:justify-normal md:gap-x-5 mt-5">
        <h6 className="font-semibold text-lg md:text-xl">{title2}</h6>
        {more ? (
          <div
            className="flex items-center gap-x-3 font-medium cursor-pointer hover:underline text-base"
            style={{ color: color }}
            onClick={handleClick}
          >
            <p>View All</p>
            <BsFillArrowUpRightCircleFill />
          </div>
        ) : null}
      </div>
      {para ? <p className="text-sm mt-4 tracking-wide">{para}</p> : null}
    </div>
  );
};

export default Title;
