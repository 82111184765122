import React from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";

const Category = ({ data }) => {
  const navigate = useNavigate();

  const handleNavigate = (value) => {
    if (value === "cars") {
      navigate(`/cars`);
    } else if (value === "jobs") {
      navigate(`/jobs`);
    } else if (value === "real-estate") {
      navigate(`/real-estate`);
    } else if (value === "motorcycles-scooters") {
      navigate(`/bikes`);
    } else if (value === "pgflatmates") {
      navigate(`/pgflatmates`);
    } else {
      navigate(`/search-result?genre=${value}&type=offering`);
    }
  };

  const SamplePrevArrow = (props) => {
    const { className, onClick } = props;

    return (
      <div onClick={onClick} className={className}>
        <IoIosArrowBack />
      </div>
    );
  };

  const SampleNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div onClick={onClick} className={className}>
        <IoIosArrowForward />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1.5,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 430,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="w-full mt-5 md:mt-7">
      {data && data.length > 5 ? (
        <Slider {...settings}>
          {data &&
            data.map((val, i) => {
              return (
                <div className="px-1 md:px-3" key={i}>
                  <div
                    className=" cursor-pointer border flex items-center justify-center flex-col py-5 rounded-xl ease-in-out transition-all duration-500 hover:border-primary"
                    onClick={() => handleNavigate(val.slug)}
                  >
                    <div className="w-14 h-14 mx-auto overflow-hidden">
                      <img
                        src={val.cat_thumb}
                        alt="img"
                        className=" object-contain w-full h-full"
                      />
                    </div>
                    <h6 className="font-medium text-sm text-center mt-2 py-2">
                      {val.cat_name}
                    </h6>
                  </div>
                </div>
              );
            })}
        </Slider>
      ) : (
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 justify-items-center gap-x-5">
          {data &&
            data.map((val) => {
              return (
                <div
                  key={val.cat_id}
                  onClick={() => handleNavigate(val.slug)}
                  className="mt-8 py-5 cursor-pointer border rounded-xl w-full ease-in-out transition-all duration-500 hover:border-primary"
                >
                  <div className="w-14 h-14 mx-auto overflow-hidden">
                    <img
                      src={val.cat_image}
                      alt="img"
                      className=" object-contain w-full h-full"
                    />
                  </div>
                  <h6 className="font-medium text-sm text-center mt-2 py-2">
                    {val.cat_name}
                  </h6>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default Category;
