import React, { useEffect, useState } from "react";
import {
  AdsCard,
  Categories,
  GridAdsCard,
  Head,
  HeroSection,
  Loading,
  Newsletter,
  Signup,
  Title,
} from "../../Component";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchGetAllCategories } from "../../Redux/Category/action";
import Cookies from "js-cookie";
import { fetchGetAds, removeAdsData } from "../../Redux/Ads/action";
import { useNavigate } from "react-router-dom";
import {
  fetchAddtoWishlist,
  fetchRemoveWishlistByPostId,
  removeWishlistData,
} from "../../Redux/Wishlist/action";
import { toast } from "react-toastify";
import { scrollToTop } from "../../Component/NavigateTop";
import { pgHome } from "../../Assets/Assest";

const PgFlatmates = () => {
  const [showCategories, setShowCategories] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [type, setType] = useState("");

  const data = localStorage.getItem("search-location");
  const parsedLocationData = JSON.parse(data);

  const [latitude, setLatitude] = useState(
    parsedLocationData ? parsedLocationData.latitude : ""
  );
  const [longitude, setLongitude] = useState(
    parsedLocationData ? parsedLocationData.longitude : ""
  );
  const [location, setLocation] = useState(
    parsedLocationData ? parsedLocationData.location : ""
  );

  const dispatch = useDispatch();
  const token = Cookies.get("token");
  const navigate = useNavigate();

  const catLoading = useSelector(
    (state) => state.categories.isloading,
    shallowEqual
  );

  const loading = useSelector((state) => state.ads.isloading, shallowEqual);

  const ads = useSelector((state) => state.ads.ads, shallowEqual);
  const filterData = useSelector((state) => state.ads.filters, shallowEqual);

  const message = useSelector((state) => state.wishlist.message, shallowEqual);
  const errMsg = useSelector((state) => state.wishlist.errMsg, shallowEqual);

  const handleAddToWishlist = (id) => {
    scrollToTop();
    const body = {
      post_id: id,
    };
    dispatch(fetchAddtoWishlist(body, token));
  };

  const handleRemoveWishlist = (id) => {
    scrollToTop();
    const body = {
      post_id: id,
    };
    dispatch(fetchRemoveWishlistByPostId(body, token));
  };

  const handleSearch = () => {
    const data = searchData.filter((elem) => elem.label !== "type");

    if (data.length) {
      localStorage.setItem("search-data", JSON.stringify(data));
    }

    navigate(
      `/search-result?genre=pgflatmates${
        type ? `&type=${type}` : `&type=offering`
      }${keyword ? `&key=${keyword}` : ""}`
    );
  };

  useEffect(() => {
    const data = searchData.find((val) => val.label === "type");
    setType(data?.value);
  }, [searchData]);

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetAllCategories());
    dispatch(fetchGetAds("pgflatmates", latitude, longitude, location, token));
    return () => {
      dispatch(removeAdsData());
    };
  }, []);

  useEffect(() => {
    const filter = setSearchData(() => {
      return [
        ...searchData,
        {
          label: "latitudes[]",
          value: latitude,
        },
        {
          label: "longitudes[]",
          value: longitude,
        },
        {
          label: "location",
          value: location,
        },
      ];
    });

    latitude !== "" &&
      longitude !== "" &&
      localStorage.setItem("search-data", JSON.stringify(filter));
  }, [latitude, longitude, location]);

  useEffect(() => {
    message && toast.success(message);
    message && dispatch(removeWishlistData());
    setTimeout(() => {
      message &&
        dispatch(
          fetchGetAds("pgflatmates", latitude, longitude, location, token)
        );
    }, 100);
  }, [message]);

  useEffect(() => {
    errMsg && toast.error(errMsg);
    errMsg && dispatch(removeWishlistData());
    setTimeout(() => {
      errMsg &&
        dispatch(
          fetchGetAds("pgflatmates", latitude, longitude, location, token)
        );
    }, 100);
  }, [errMsg]);

  if (catLoading || loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }
  return (
    <div className="pt-16 md:pt-24 relative">
      {showCategories ? (
        <div
          className="absolute w-full h-full bg-black opacity-10"
          onClick={() => {
            setShowCategories(false);
          }}
        ></div>
      ) : null}
      <Categories showCategories={showCategories} />
      <Head color="#A950A7" setShowCategories={setShowCategories} />
      <HeroSection
        pic={pgHome}
        filterData={filterData}
        searchData={searchData}
        setSearchData={setSearchData}
        setKeyword={setKeyword}
        keyword={keyword}
        color="#A950A7"
        handleSearch={handleSearch}
        setLatitude={setLatitude}
        setLocation={setLocation}
        setLongitude={setLongitude}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
      />
      {token ? null : (
        <Signup
          color="#A950A7"
          bgColor="rgb(169 80 167 / var(--tw-bg-opacity))"
        />
      )}

      <div className="w-11/12 xl:w-3/4 mx-auto bg-white py-10">
        {ads && ads.length ? (
          <div className="mt-20">
            <Title
              more={true}
              color="#A950A7"
              title1="Our Products"
              title2="Explore Our Latest Ads"
              handleClick={() =>
                navigate(`/search-result?genre=pgflatmates&type=offering`)
              }
            />

            {ads.length > 3 ? (
              <AdsCard
                data={ads}
                handleAddToWishlist={handleAddToWishlist}
                handleRemoveWishlist={handleRemoveWishlist}
                slugs="pgflatmates"
                category="pgflatmates"
              />
            ) : (
              <div className="w-full grid grid-cols-1 pb-5 sm:grid-cols-2 lg:grid-cols-3 gap-x-3 xl:gap-x-6">
                {ads.map((val) => {
                  return (
                    <div key={val.post_id}>
                      <GridAdsCard
                        data={val}
                        slugs="pgflatmates"
                        category="pgflatmates"
                        handleAddToWishlist={handleAddToWishlist}
                        handleRemoveWishlist={handleRemoveWishlist}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        ) : null}
      </div>
      <Newsletter
        color="#A950A7"
        bgColor="rgb(169 80 167 / var(--tw-bg-opacity))"
      />
    </div>
  );
};

export default PgFlatmates;
