import React from "react";
import InputField from "../InputField/InputField";
import PasswordField from "../PasswordField/PasswordField";
import TextArea from "../InputField/TextArea";
import Select from "../SelectField/Select";
import Checkbox from "../Checkboxes/Checkbox";

const FormikControl = (props) => {
  const { control, ...rest } = props;
  switch (control) {
    case "input":
      return <InputField {...rest} />;

    case "password":
      return <PasswordField {...rest} />;

    case "textarea":
      return <TextArea {...rest} />;

    case "select":
      return <Select {...rest} />;

    case "checkbox":
      return <Checkbox {...rest} />;

    default:
      return null;
  }
};

export default FormikControl;
