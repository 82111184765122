import React, { useEffect } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import {
  fetchDeleteSaveSearch,
  fetchGetSaveSearch,
  removeAdsData,
} from "../../Redux/Ads/action";
import Cookies from "js-cookie";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { offering, wanted } from "../../Assets/Assest";
import { useNavigate } from "react-router-dom";

const SavedSearchCard = ({ data, page }) => {
  const token = Cookies.get("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const message = useSelector((state) => state.ads.message, shallowEqual);
  const errMsg = useSelector((state) => state.ads.errMsg, shallowEqual);

  const handleDeleteSaveSearch = (e, id) => {
    e.preventDefault();
    const body = {
      searchid: id,
    };

    dispatch(fetchDeleteSaveSearch(body, token));
  };

  const handleNavigate = (data) => {
    const slugs = data.filter((val) => val.parameter === "slug");
    const type = data.find((val) => val.parameter === "type");
    const restData = data.filter(
      (val) => val.parameter !== "slug" && val.parameter !== "type"
    );
    const location = data.find((val) => val.parameter === "location");
    const longitude = data.find((val) => val.parameter === "longitudes");
    const latitude = data.find((val) => val.parameter === "latitudes");

    const locationData = {
      location: location?.value,
      latitude: latitude?.value?.[0],
      longitude: longitude?.value?.[0],
    };

    const filter = restData?.map((val) => {
      return {
        label: val.parameter,
        value: val.value,
      };
    });

    localStorage.setItem("search-data", JSON.stringify(filter));

    localStorage.setItem("search-location", JSON.stringify(locationData));

    slugs?.length > 1
      ? navigate(
          `/search-result?slug=${slugs?.[0]?.value}&genre=${slugs?.[1]?.value}&type=${type?.value}`
        )
      : navigate(
          `/search-result?genre=${slugs?.[0]?.value}&type=${type?.value}`
        );
  };

  useEffect(() => {
    message && toast.success(message);
    errMsg && toast.error(errMsg);
    (message || errMsg) && dispatch(removeAdsData());

    setTimeout(() => {
      (message || errMsg) && dispatch(fetchGetSaveSearch(page, token));
    });
  }, [message || errMsg]);

  return (
    <div>
      {data &&
        data.map((val, i) => {
          const address = val.searched_options.find(
            (itm) => itm.parameter === "property_address"
          );

          const data = val.searched_options.filter(
            (itm) =>
              itm.parameter !== "property_address" &&
              itm.parameter !== "property_suburb" &&
              itm.parameter !== "property_state" &&
              itm.parameter !== "slug" &&
              itm.parameter !== "latitudes" &&
              itm.parameter !== "longitudes" &&
              (itm.value !== "" || itm.value !== null)
          );

          const categ = val.searched_options.filter(
            (itm) => itm.parameter === "slug"
          );

          return (
            <div
              className="w-full border rounded-xl mt-5 cursor-pointer"
              onClick={() => handleNavigate(val.searched_options)}
              key={val.id}
            >
              <div className="w-full flex items-center justify-between p-5">
                <h6 className="w-full font-medium flex items-center gap-x-2">
                  <p>#{i + 1} </p>
                  <div className="w-full flex items-center gap-x-5">
                    {categ.map((val, i) => {
                      return (
                        <div
                          className="w-fit flex items-center gap-x-5"
                          key={i}
                        >
                          <p>{val.label}</p>
                        </div>
                      );
                    })}{" "}
                  </div>
                </h6>
                <div
                  className="p-2 w-fit rounded-full hover:bg-gray-100"
                  onClick={(e) => handleDeleteSaveSearch(e, val.id)}
                >
                  <RiDeleteBin6Line className="text-red-500" />
                </div>
              </div>

              <hr className="w-full" />
              <div className="flex items-center gap-x-5 flex-wrap  p-5">
                {address && address.value
                  ? address.value.map((val, i) => {
                      return (
                        <h6
                          key={i}
                          className="font-medium w-fit text-xs md:text-sm border p-2 rounded-md border-primary mt-2"
                        >
                          {val}
                        </h6>
                      );
                    })
                  : null}

                {data &&
                  data.map((val, i) => {
                    return (
                      <div
                        key={i}
                        className="flex w-fit items-center gap-x-2 border border-primary p-2 rounded-md text-xs md:text-sm mt-2"
                      >
                        {val?.icon ||
                        val?.value === "wanted" ||
                        val?.value === "offering" ? (
                          <div className="w-4 h-4">
                            <img
                              src={
                                val?.value === "wanted"
                                  ? wanted
                                  : val?.value === "offering"
                                  ? offering
                                  : val?.icon
                              }
                              alt=""
                              style={{
                                filter:
                                  val?.value === "wanted"
                                    ? "brightness(0.8) invert(1) sepia(100%) saturate(10000%) hue-rotate(70deg)"
                                    : "sepia(100%) hue-rotate(120deg) saturate(500%)",
                              }}
                              className="w-full h-full object-contain text-green-500"
                            />
                          </div>
                        ) : null}
                        <p className="capitalize">
                          {val?.label?.replace(/,\s*$/, "")}
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default SavedSearchCard;
