import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Button, FormikControl } from "../../../Component";
import Cookies from "js-cookie";
import {
  fetchGetCampaign,
  fetchSubmitCampagin,
  removeCampaignData,
} from "../../../Redux/Campaign/action";
import { toast } from "react-toastify";

const Questions = () => {
  const [ans, setAns] = useState("");

  const dispatch = useDispatch();
  const token = Cookies.get("token");

  const campaign = useSelector(
    (state) => state.campaign.campaign,
    shallowEqual
  );
  const question = useSelector(
    (state) => state.campaign.question,
    shallowEqual
  );

  const answers = useSelector((state) => state.campaign.answers, shallowEqual);
  const profile = useSelector((state) => state.profile.profile, shallowEqual);

  const errMsg = useSelector((state) => state.campaign.errMsg, shallowEqual);

  //   initial values
  const initialValues = {
    firstName: profile?.first_name ? profile?.first_name : "",
    lastName: profile?.last_name ? profile?.last_name : "",
    contact: profile?.contact_number ? profile?.contact_number : "",
    email: profile?.user_email ? profile?.user_email : "",
  };

  // check formik validation
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Required*"),
    lastName: Yup.string().required("Required*"),
    contact: Yup.string().required("Required*"),
    email: Yup.string().required("Required*"),
  });

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    const body = {
      signup: token ? profile?.userid : "0",
      campaign_id: campaign.id,
      answer: ans,
      first_name: values.firstName,
      last_name: values.lastName,
      phone: values.contact,
      email: values.email,
    };
    dispatch(fetchSubmitCampagin(body));

    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
    }, 1000);
  };

  useEffect(() => {
    errMsg && toast.error(errMsg);
    errMsg && dispatch(removeCampaignData());
    setTimeout(() => {
      errMsg && dispatch(fetchGetCampaign());
    }, 100);
  }, [errMsg]);

  return (
    <div className="w-11/12 md:w-3/4 xl:w-1/2 bg-white rounded-lg absolute top-32 z-40 left-5 md:left-[13%] xl:left-[23%]">
      <h6 className="text-xl text-gray-600 p-5">{campaign.image_title}</h6>
      <hr className="w-full" />
      <div className="w-full p-5">
        <p className="text-gray-500 text-lg">Question : {question.question}</p>
        <p className="text-sm text-gray-400 mt-5">Possible Answers</p>
        {answers.map((val) => {
          return (
            <div className="w-full flex items-center gap-x-4 mt-3" key={val.id}>
              <input
                type="radio"
                className="w-3 h-3"
                name="radio"
                value={val.id}
                onChange={(e) => setAns(val.id)}
              />
              <p className="text-sm text-gray-600">{val.answer}</p>
            </div>
          );
        })}

        <div className="w-full mt-7">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnMount
          >
            {(formik) => (
              <Form>
                <div className="w-full sm:flex items-center gap-x-5">
                  <div className="w-full">
                    <FormikControl
                      label="First Name"
                      control="input"
                      name="firstName"
                      placeholder="Your First Name"
                    />
                  </div>

                  <div className="w-full mt-5 sm:mt-0">
                    <FormikControl
                      label="Last Name"
                      control="input"
                      name="lastName"
                      placeholder="Your Last Name"
                    />
                  </div>
                </div>

                <div className="w-full sm:flex items-center gap-x-5 mt-5">
                  <div className="w-full">
                    <FormikControl
                      label="Contact"
                      control="input"
                      name="contact"
                      placeholder="Your Contact Number"
                    />
                  </div>

                  <div className="w-full mt-5 sm:mt-0">
                    <FormikControl
                      label="Email Address"
                      control="input"
                      name="email"
                      placeholder="Your Email Address"
                    />
                  </div>
                </div>

                <div className="w-full flex items-center justify-end mt-5">
                  <Button
                    value="Submit"
                    className="w-fit text-white bg-primary rounded-md px-7 py-2"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Questions;
