import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  blogs: [],
  category: [],
  totalData: "",
  detail: "",
};

export const Blogs = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_BLOGS:
      return {
        ...state,
        isloading: false,
        success: true,
        blogs: action.payload.blogs,
        category: action.payload.categories,
        totalData: action.payload.blog_numbers,
      };

    case ActionTypes.GET_BLOGS_DETAIL:
      return {
        ...state,
        isloading: false,
        success: true,
        detail: action.payload.blog,
      };

    case ActionTypes.REMOVE_BLOGS_DATA:
      return {
        isloading: true,
        success: false,
        blogs: [],
        category: [],
        totalData: "",
        detail: "",
      };

    default:
      return state;
  }
};
