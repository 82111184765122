// export const BaseUrl = "https://re1.classibazaar.au/api/v3";
export const BaseUrl = "https://classibazaar.com.au/cbazaar_api/api/v3";

// export const BaseUrl2 = "https://re1.classibazaar.au/api";
export const BaseUrl2 = "https://classibazaar.com.au/cbazaar_api/api";

export const STRIPE_TEST =
  "sk_test_51N5ksDDe3aZ3gA2zrQD6DM8wGhJEO6wYMCfcFqLs62vjmmCeoRZceuwjl6X5mhtpDJGAE9oCFh0Wih9rzD2VwRJ700CU8Lj66n";

export const STRIPE_LIVE = "sk_live_CXb0kUPBDI9aOomn5EtOYZV400XSnA7kOH";
