import axios from "axios";
import { BaseUrl, STRIPE_LIVE } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const paymentsLoading = () => ({
  type: ActionTypes.PAYMENTS_LOADING,
});

export const removePaymentData = () => ({
  type: ActionTypes.REMOVE_PAYMENT_DATA,
});

export const fetchGetPaymentHistory =
  (page, status, token) => async (dispatch) => {
    await axios
      .get(
        `${BaseUrl}/payment_history_new?page=${page}&payment_method=${status}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.GET_PAYMENT_HISTORY,
          payload: data,
        });
      });
  };

export const fetchStripePayment =
  (body, duration, paymentMethod, id, packages, selectedPackages, token) =>
  async (dispatch) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${STRIPE_LIVE}`,
      },
    };
    await axios
      .post(`https://api.stripe.com/v1/tokens`, body, requestOptions)
      .then(({ data }) => {
        const body = {
          source: data?.id,
        };

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${STRIPE_LIVE}`,
          },
        };
        axios
          .post(`https://api.stripe.com/v1/customers`, body, requestOptions)
          .then(({ data }) => {
            const body = {
              amount: (duration.price * 100).toFixed(0),
              currency: "AUD",
              statement_descriptor: "CHR_CLASSIDEALS",
              customer: data?.id,
            };
            const requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${STRIPE_LIVE}`,
              },
            };

            axios
              .post(`https://api.stripe.com/v1/charges`, body, requestOptions)
              .then(({ data }) => {
                const body = {
                  transaction_id: data?.id,
                  amount: duration.price,
                  paymethod: paymentMethod,
                  duration_id: duration.id,
                  post_id: id,
                  featured: packages.includes("Featured") ? 1 : 0,
                  urgent: packages.includes("Urgent") ? 1 : 0,
                  spotlight: packages.includes("Spotlight") ? 1 : 0,
                  logo: packages.includes("Logo") ? 1 : 0,
                  featured_already: selectedPackages.includes("Featured")
                    ? 1
                    : 0,
                  urgent_already: selectedPackages.includes("Urgent") ? 1 : 0,
                  logo_already: selectedPackages.includes("Logo") ? 1 : 0,
                  spot_already: selectedPackages.includes("Spotlight") ? 1 : 0,
                };
                axios
                  .post(`${BaseUrl}/payment`, body, {
                    headers: { Authorization: `Bearer ${token}` },
                  })
                  .then(({ data }) => {
                    dispatch({
                      type: ActionTypes.STRIPE_PAYMENT_SUCCESS,
                      payload: data,
                    });
                  });
              });
          });
      })

      .catch((e) => {
        dispatch({
          type: ActionTypes.STRIPE_PAYMENT_FAIL,
          payload: e?.response?.data,
        });
      });
  };

export const fetchClassiCreditPayment =
  (paymentMethod, duration, id, packages, selectedPackages, token) =>
  async (dispatch) => {
    const body = {
      amount: duration.price,
      paymethod: paymentMethod,
      transaction_id: "classi_credit",
      duration_id: duration.id,
      post_id: id,
      featured: packages.includes("Featured") ? 1 : 0,
      urgent: packages.includes("Urgent") ? 1 : 0,
      spotlight: packages.includes("Spotlight") ? 1 : 0,
      logo: packages.includes("Logo") ? 1 : 0,
      featured_already: selectedPackages.includes("Featured") ? 1 : 0,
      urgent_already: selectedPackages.includes("Urgent") ? 1 : 0,
      logo_already: selectedPackages.includes("Logo") ? 1 : 0,
      spot_already: selectedPackages.includes("Spotlight") ? 1 : 0,
    };
    await axios
      .post(`${BaseUrl}/payment`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.CLASSI_CREDIT_PAYMENT_SUCCESS,
          payload: data,
        });
      })
      .catch((e) => {
        dispatch({
          type: ActionTypes.CLASSI_CREDIT_PAYMENT_FAIL,
          payload: e?.response?.data,
        });
      });
  };
