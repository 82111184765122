import React, { useState } from "react";
import { FaMoneyBills } from "react-icons/fa6";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Button, ImagePopup, Loading, OTPpopup } from "../../Component";
import Avatar from "react-avatar";

import Details from "./Details";
import Password from "./Password";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import {
  fetchGetUserProfile,
  removeProfilesData,
} from "../../Redux/Profile/action";
import { scrollToTop } from "../../Component/NavigateTop";

const Profile = () => {
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [showOtpPopup, setShowOtpPopup] = useState(false);

  const dispatch = useDispatch();
  const token = Cookies.get("token");
  const loading = useSelector((state) => state.profile.isloading, shallowEqual);
  const profile = useSelector((state) => state.profile.profile, shallowEqual);

  const message = useSelector((state) => state.profile.message, shallowEqual);
  const errMsg = useSelector((state) => state.profile.errMsg, shallowEqual);

  useEffect(() => {
    message && toast.success(message);
    message && dispatch(removeProfilesData());
    message && setShowImagePopup(false);
    message && setShowOtpPopup(false);
    setTimeout(() => {
      message && dispatch(fetchGetUserProfile(token));
    }, 100);
  }, [message]);

  useEffect(() => {
    errMsg && toast.error(errMsg);
    errMsg && dispatch(removeProfilesData());
    errMsg && setShowImagePopup(false);
    errMsg && setShowOtpPopup(false);
    setTimeout(() => {
      errMsg && dispatch(fetchGetUserProfile(token));
    }, 100);
  }, [errMsg]);

  useEffect(() => {
    scrollToTop();
  }, []);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="w-full h-full mt-2 relative">
      {showImagePopup || showOtpPopup ? (
        <div
          className="w-full h-full absolute top-0 bg-black opacity-10"
          onClick={() => {
            setShowImagePopup(false);
            setShowOtpPopup(false);
          }}
        ></div>
      ) : null}

      {showImagePopup && <ImagePopup setImagePopupModal={setShowImagePopup} />}
      {showOtpPopup && <OTPpopup setOtpPopup={setShowOtpPopup} />}
      <div className="w-full flex items-center gap-x-3">
        <h6 className="text-2xl font-semibold">Profile Setting</h6>
        <div className="w-fit py-1 px-3 flex items-center gap-x-3 border rounded-full bg-white">
          <FaMoneyBills className="text-xl text-primary" />
          <p className="font-semibold">{profile.classi_credit} Coins</p>
        </div>
      </div>

      {/* profile picture */}
      <div className="w-full border bg-white rounded-xl  mt-10">
        <p className="font-medium p-3">Profile Picture</p>
        <hr className="w-full" />

        <div className="w-full flex md:items-center gap-x-5 p-5">
          {profile && profile.image ? (
            <div className="w-24 h-24 rounded-full overflow-hidden">
              <img
                src={profile.image}
                alt=""
                className="w-full h-full object-fill"
              />
            </div>
          ) : (
            <Avatar
              name={profile && profile.first_name}
              size={90}
              round={true}
              color="#2E8B4A"
            />
          )}

          <div className="w-8/12">
            <p className="font-medium text-sm md:text-base">
              File smaller than 10MB and at least 400px by 400px
            </p>
            <div className="w-full flex items-center gap-x-5 mt-5">
              <Button
                value="Upload Photo"
                handleClick={() => setShowImagePopup(true)}
                className="bg-primary text-white rounded-xl text-sm px-7 py-3"
              />
            </div>
          </div>
        </div>
      </div>

      {/* profile details */}
      <Details setShowOtpPopup={setShowOtpPopup} />

      {/* change password */}
      <Password />
    </div>
  );
};

export default Profile;
