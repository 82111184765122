import React from "react";
import { useEffect } from "react";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../Component/NavigateTop";
import { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchGetDeleteReason,
  fetchGetMyAds,
  fetchGetPaymentTypes,
  removeMyAdsData,
} from "../../Redux/MyAds/action";
import Cookies from "js-cookie";
import { Button, FormikControl, Loading } from "../../Component";
import * as Yup from "yup";
import { card, classiBazarLogo } from "../../Assets/Assest";
import { Form, Formik } from "formik";
import {
  fetchClassiCreditPayment,
  fetchStripePayment,
  removePaymentData,
} from "../../Redux/Payments/action";
import { toast } from "react-toastify";
import { fetchGetUserProfile } from "../../Redux/Profile/action";
import { RiImageAddFill } from "react-icons/ri";
import { fetchUploadLogo } from "../../Redux/Logo/action";
import { fetchGetAdsDetail, removeAdsData } from "../../Redux/Ads/action";

const items = [
  {
    id: 1,
    name: "Featured",
    detail: "Have Your Ad Top Of The Category Listing",
    color: "#E8F3EC",
  },
  {
    id: 2,
    name: "Urgent",
    detail: "Let People Know You Want To Sell, Rent Or Hire Quickly",
    color: "#E6F1FC",
  },
  {
    id: 3,
    name: "Spotlight",
    detail: "Have Your Ad Seen On The Classibazaar Home Page",
    color: "#F9E8E9",
  },
  {
    id: 4,
    name: "Logo",
    detail: "Have Your Logo Seen On The Classibazaar Jobs Listing Page.",
  },
];

const itemss = [
  {
    id: 1,
    name: "Featured",
    detail: "Have Your Ad Top Of The Category Listing",
    color: "#E8F3EC",
  },
  {
    id: 2,
    name: "Urgent",
    detail: "Let People Know You Want To Sell, Rent Or Hire Quickly",
    color: "#E6F1FC",
  },

  {
    id: 4,
    name: "Logo",
    detail: "Have Your Logo Seen On The Classibazaar Jobs Listing Page.",
  },
];

const UpgradePost = ({ id }) => {
  const [packages, setPackages] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [logoImage, setLogoImage] = useState();

  const [showDuration, setShowDuration] = useState(false);

  const [durations, setDurations] = useState({
    id: "",
    price: "",
  });

  const [paymentMethod, setPaymentMethod] = useState("");

  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = Cookies.get("token");

  const loading = useSelector((state) => state.myAds.isloading, shallowEqual);
  const duration = useSelector((state) => state.myAds.durations, shallowEqual);
  const classi_credit_value = useSelector(
    (state) => state.myAds.classi_credit_value,
    shallowEqual
  );
  const profile = useSelector((state) => state.profile.profile, shallowEqual);

  const message = useSelector((state) => state.payment.message, shallowEqual);
  const errMsg = useSelector((state) => state.payment.errMsg, shallowEqual);

  const logoMsg = useSelector((state) => state.logo.message, shallowEqual);
  const logoErrMsg = useSelector((state) => state.logo.errMsg, shallowEqual);
  const logoUrl = useSelector((state) => state.logo.logo, shallowEqual);
  const detail = useSelector((state) => state.ads.detail, shallowEqual);
  const detailLoading = useSelector(
    (state) => state.ads.isloading,
    shallowEqual
  );

  const navigateBack = () => {
    navigate(-1);
  };

  const handleClickPackages = (name) => {
    setDurations({
      id: "",
      price: "",
    });
    if (packages.includes(name)) {
      setPackages(packages.filter((val) => val !== name));
    } else {
      setPackages([...packages, name]);
    }
  };

  const calculateSumOfPrices = (typeArray) => {
    const prices = typeArray?.map((item) => parseFloat(item.price));
    return prices
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
      .toFixed(2);
  };

  const calculateSumOfActualPrice = (typeArray) => {
    const prices = typeArray?.map((item) => parseFloat(item.actual_price));
    return prices
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
      .toFixed(2);
  };

  const handleSetDuration = (id) => {
    const abc = duration.find((val) => val.duration_id === id);
    const prices = abc?.type?.map((item) => parseFloat(item.actual_price));
    return setDurations({
      id: id,
      price: prices
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
        .toFixed(2),
    });
  };

  const handleDateChange = (event) => {
    // The value of the input is in the format "YYYY-MM"
    const selectedDate = event.target.value;

    // Extract the year and month from the selected date
    const year = selectedDate.substring(0, 4);
    const month = selectedDate.substring(5, 7);

    // Update the state variables
    setSelectedYear(year);
    setSelectedMonth(month);
  };

  //   initial values
  const initialValues = {
    number: "",
    cvc: "",
  };

  // check formik validation
  const validationSchema = Yup.object().shape({
    number: Yup.string().min(16, "Too Short!").max(16, "Too Long!"),
    cvc: Yup.string().required("Required*"),
  });

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    scrollToTop();

    const body = {
      "card[number]": values.number,
      "card[exp_month]": selectedMonth,
      "card[exp_year]": selectedYear,
      "card[cvc]": values.cvc,
    };

    if (packages.includes("Logo") && !logoImage) {
      toast.error("Please upload logo");
    } else {
      dispatch(
        fetchStripePayment(
          body,
          durations,
          paymentMethod,
          id,
          packages,
          selectedPackages,
          token
        )
      );
    }

    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
    }, 1000);
  };

  const handlePayClassiCredit = () => {
    if (packages.includes("Logo") && !logoImage) {
      toast.error("Please upload logo");
    } else {
      dispatch(
        fetchClassiCreditPayment(
          paymentMethod,
          durations,
          id,
          packages,
          selectedPackages,
          token
        )
      );
    }
  };

  const uploadImage = (e) => {
    setLogoImage(e.target.files[0]);
    const formData = new FormData();
    formData.append("files", e.target.files[0]);
    formData.append("post_id", id);
    dispatch(fetchUploadLogo(formData, token));
  };

  useEffect(() => {
    detail &&
      detail.featured === "1" &&
      setSelectedPackages((prev) => [...prev, "Featured"]);
    detail &&
      detail.logo === "1" &&
      setSelectedPackages((prev) => [...prev, "Logo"]);

    detail &&
      detail.spoted === "1" &&
      setSelectedPackages((prev) => [...prev, "Spotlight"]);

    detail &&
      detail.urgent === "1" &&
      setSelectedPackages((prev) => [...prev, "Urgent"]);
  }, [detail]);

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetPaymentTypes(packages, token));
  }, [packages]);

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetAdsDetail(id, token));
    return () => {
      dispatch(removeAdsData());
    };
  }, []);

  useEffect(() => {
    message && toast.success(message);
    message && dispatch(removePaymentData());
    message && dispatch(removeMyAdsData());
    message && navigate("/me/my-ads");
    setTimeout(() => {
      message && dispatch(fetchGetMyAds(1, "", token));
      message && dispatch(fetchGetDeleteReason(token));
      message && dispatch(fetchGetUserProfile(token));
    });
  }, [message]);

  useEffect(() => {
    errMsg && toast.error(errMsg);
    errMsg && dispatch(removePaymentData());
  }, [errMsg]);

  useEffect(() => {
    logoMsg && toast.success(logoMsg);
    logoErrMsg && toast.error(logoErrMsg);
    logoErrMsg && setLogoImage();
  }, [logoMsg, logoErrMsg]);

  if (loading || detailLoading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="w-full mt-5">
      <div
        className="flex items-center gap-x-2 cursor-pointer"
        onClick={navigateBack}
      >
        <IoMdArrowBack />
        <p>Back</p>
      </div>
      {packages && packages.includes("Logo") && showDuration ? (
        <div className="w-full  border rounded-lg bg-white mt-5">
          <p className="font-medium p-5">Upload Your Logo</p>
          <hr />
          <p className="font-medium pt-5 px-5 text-gray-600">
            Please select logo
          </p>
          <div className="flex w-full flex-wrap items-center gap-x-5 p-5">
            <div className="mt-5">
              <label>
                <div
                  title="upload image"
                  className="h-16 w-16 sm:h-20 xl:h-24 sm:w-20 xl:w-24 flex justify-center items-center cursor-pointer border-2 border-gray-300 bg-gray-200 hover:bg-gray-300"
                >
                  <RiImageAddFill className="text-4xl text-gray-600" />
                  <input
                    type="file"
                    accept="image/jpg, image/png, image/webp, image/jpeg"
                    onChange={(e) => uploadImage(e)}
                    className="hidden h-40"
                  />
                </div>
              </label>
            </div>

            {logoUrl && (
              <div className="h-16 w-16 sm:h-20 xl:h-24 sm:w-20 xl:w-24 rounded-lg mt-5 relative">
                <img
                  src={logoUrl}
                  alt=""
                  className="w-full h-full object-fill"
                />
              </div>
            )}
          </div>
        </div>
      ) : null}

      {/* durations */}
      {showDuration ? (
        <div className="w-full  border rounded-lg bg-white mt-5">
          <p className="font-medium p-5">Duration</p>
          <hr />
          <p className="font-medium pt-5 px-5 text-gray-600">
            Please select duration
          </p>
          <div className="w-full grid grid-cols-2 lg:grid-cols-3 gap-x-5 p-5 pt-0">
            {duration &&
              duration.map((val) => {
                return (
                  <div
                    key={val.duration_id}
                    className={`w-full  border rounded-xl mt-5 py-3 cursor-pointer ${
                      durations.id === val.duration_id
                        ? "border-black border-2"
                        : ""
                    }`}
                    onClick={() => handleSetDuration(val.duration_id)}
                    style={{ backgroundColor: val.bg_color, color: val.color }}
                  >
                    <h6 className="text-lg font-medium text-center">
                      {val.duration_title}
                    </h6>

                    <p className="text-base text-center font-medium mt-5">
                      {val.no_of_days} days
                    </p>

                    <ul className="mt-5">
                      {val?.type.map((type) => (
                        <li
                          key={type.cost_id}
                          className="text-sm text-center py-1"
                        >
                          {type.cost_type}: {type.actual_price}
                        </li>
                      ))}
                    </ul>

                    <p
                      className={`text-base font-medium mt-5 text-center line-through`}
                    >
                      Price: {calculateSumOfPrices(val?.type)} Aud
                    </p>
                    <p className={`text-base font-medium mt-5 text-center`}>
                      Price: {calculateSumOfActualPrice(val?.type)} Aud
                    </p>
                  </div>
                );
              })}
          </div>

          <hr className="w-full" />
          <div className="w-full flex justify-end gap-x-5 p-5">
            <Button
              value="Back"
              handleClick={() => setShowDuration(false)}
              className="w-fit px-7 py-3 bg-gray-200 rounded-xl text-gray-800"
            />
          </div>
        </div>
      ) : (
        <div className="w-full  border rounded-lg bg-white mt-5">
          <p className="font-medium p-5">Make your Ad Stand Out</p>
          <hr />
          <p className="font-medium pt-5 px-5 text-gray-600">
            Please select package
          </p>

          <div className="w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-5 p-5 pt-0">
            {detail.images && detail.images.length
              ? items.map((val) => {
                  return (
                    <div
                      className={`w-full border mt-5 rounded-xl flex flex-col p-5 items-center cursor-pointer ${
                        packages.includes(val.name) ||
                        selectedPackages.includes(val.name)
                          ? `border-primary bg-green-50`
                          : ``
                      }`}
                      key={val.id}
                      onClick={() => {
                        if (selectedPackages.includes(val.name)) {
                        } else {
                          handleClickPackages(val.name);
                        }
                      }}
                    >
                      <p
                        className={`w-fit text-sm px-3 py-0.5 border rounded-full ${
                          val.id === 1
                            ? "bg-green-100 text-primary"
                            : val.id === 2
                            ? "bg-red-100 text-carPrimary"
                            : val.id === 3
                            ? "bg-blue-100 text-jobPrimary"
                            : "bg-gray-100"
                        }  `}
                      >
                        {val.name}
                      </p>
                      <p className="text-xs text-gray-500 mt-3 text-center">
                        {val.detail}
                      </p>
                    </div>
                  );
                })
              : itemss.map((val) => {
                  return (
                    <div
                      className={`w-full border rounded-xl flex flex-col p-5 items-center cursor-pointer ${
                        packages.includes(val.name) ||
                        selectedPackages.includes(val.name)
                          ? `border-primary bg-green-50`
                          : ``
                      }`}
                      key={val.id}
                      onClick={() => {
                        if (selectedPackages.includes(val.name)) {
                        } else {
                          handleClickPackages(val.name);
                        }
                      }}
                    >
                      <p
                        className={`w-fit text-sm px-3 py-0.5 border rounded-full ${
                          val.id === 1
                            ? "bg-green-100 text-primary"
                            : val.id === 2
                            ? "bg-red-100 text-carPrimary"
                            : val.id === 3
                            ? "bg-blue-100 text-jobPrimary"
                            : "bg-gray-100"
                        }  `}
                      >
                        {val.name}
                      </p>
                      <p className="text-xs text-gray-500 mt-3 text-center">
                        {val.detail}
                      </p>
                    </div>
                  );
                })}
          </div>
          <hr className="w-full" />
          <div className="w-full flex justify-end gap-x-5 p-5">
            <Button
              value="Next"
              handleClick={() => {
                if (packages && packages.length) {
                  setShowDuration(true);
                }
              }}
              className="w-fit px-7 py-3 rounded-xl bg-primary text-white"
            />
          </div>
        </div>
      )}

      {showDuration && durations.id ? (
        <div className="w-full border rounded-lg bg-white mt-5 p-5">
          <p className="font-medium text-gray-600">
            Please select payment method
          </p>{" "}
          <div className="w-full flex items-center gap-x-5 mt-5">
            <div
              className={`${
                paymentMethod === "Stripe" ? "bg-green-50 border-primary" : ""
              } w-full border rounded-xl p-3 flex items-center justify-between cursor-pointer`}
              onClick={() => setPaymentMethod("Stripe")}
            >
              <p className="text-sm">Card</p>
              <img src={card} alt="" className="w-8 h-5 object-contain" />
            </div>
            <div
              className={`w-full border rounded-xl p-3 flex items-center justify-between cursor-pointer ${
                paymentMethod === "classi_credit"
                  ? "bg-green-50 border-primary"
                  : ""
              }`}
              onClick={() => setPaymentMethod("classi_credit")}
            >
              <p className="text-sm">
                ClassiCoins{" "}
                <span className="text-primary font-medium">
                  ({profile.classi_credit} ){" "}
                </span>
              </p>
              <img
                src={classiBazarLogo}
                alt=""
                className="w-5 h-5 object-conatin"
              />
            </div>
          </div>
          {paymentMethod === "Stripe" ? (
            <div className="mt-5">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                validateOnMount
              >
                {(formik) => (
                  <Form>
                    <div className="w-full">
                      <FormikControl
                        label="Card Number"
                        control="input"
                        name="number"
                        placeholder="Your card Number"
                        type="Number"
                      />
                    </div>
                    <div className="w-full mt-5 flex items-center gap-x-5">
                      <div className="text-sm w-full">
                        <div className="w-full flex items-center gap-x-5 pb-2">
                          <label className="font-medium flex text-sm items-center justify-between w-full">
                            Expiration
                          </label>
                        </div>

                        <input
                          className="border border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300"
                          onChange={handleDateChange}
                          placeholder="MM-YYYY"
                          type="month"
                        />
                      </div>
                      <div className="w-full">
                        <FormikControl label="CVC" control="input" name="cvc" />
                      </div>
                    </div>

                    <Button
                      value="Pay Now"
                      className="w-full rounded-xl mt-5 bg-primary text-white py-2 md:py-3"
                    />
                  </Form>
                )}
              </Formik>
            </div>
          ) : paymentMethod === "classi_credit" ? (
            <div className="mt-7">
              <p className="w-full bg-gray-100 text-primary font-semibold rounded-xl px-5 py-2">
                $1 = {classi_credit_value} Coins
              </p>
              <Button
                value={`Use ${durations.price * classi_credit_value} Coins`}
                handleClick={handlePayClassiCredit}
                className="mt-7 border rounded-xl bg-primary w-fit px-7 text-white py-3"
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default UpgradePost;
