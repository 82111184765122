import React, { useEffect } from "react";
import { Button, FormikControl } from "../../Component";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  fetchGetUserProfile,
  fetchUpdateProfile,
  fetchVerifyMobile,
  removeProfilesData,
} from "../../Redux/Profile/action";
import Cookies from "js-cookie";
import TextError from "../../Component/Formik/Error";
import { FiCheck } from "react-icons/fi";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { toast } from "react-toastify";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";

const Details = ({ setShowOtpPopup }) => {
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const token = Cookies.get("token");

  const profile = useSelector((state) => state.profile.profile, shallowEqual);
  const [address, setAddress] = useState(
    profile?.street_address ? profile.street_address : ""
  );
  const [suburb, setSuburb] = useState(
    profile?.users?.suburb ? profile?.users?.suburb : ""
  );
  const [state, setState] = useState(
    profile?.users?.state ? profile?.users?.state : ""
  );
  const [postcode, setPostcode] = useState(
    profile?.users?.postcode ? profile?.users?.postcode : ""
  );

  const [phoneNumber, setPhoneNumber] = useState(
    profile.user_mobile ? profile.user_mobile : ""
  );

  const message = useSelector((state) => state.profile.verifyMsg, shallowEqual);
  const errMessage = useSelector(
    (state) => state.profile.verifyErr,
    shallowEqual
  );

  const handleChangeAddress = async () => {
    if (value) {
      const search = value.label;

      const promises = await geocodeByAddress(search);

      const geocodes = await Promise.all(promises);

      const place = geocodes?.[0];

      const state = place?.address_components?.find((val) => {
        return val?.types?.includes("administrative_area_level_1");
      });

      const suburb = place?.address_components?.find((val) => {
        return val?.types?.includes(
          "locality" || "political" || "administrative_area_level_2"
        );
      });

      const postcode = place?.address_components?.find((val) => {
        return val?.types?.includes("postal_code");
      });

      setAddress(place?.formatted_address);
      setState(state?.short_name);
      setSuburb(suburb?.long_name);
      setPostcode(postcode?.long_name);
    }
  };

  const initialValues = {
    first_name: profile.first_name ? profile.first_name : "",
    last_name: profile.last_name ? profile.last_name : "",
    mobile: profile.user_mobile ? profile.user_mobile : "",
    address: profile.street_address ? profile.street_address : "",
    email: profile.user_email ? profile.user_email : "",
    postcode: profile?.users?.postcode ? profile?.users?.postcode : "",
    suburb: profile?.users?.suburb ? profile?.users?.suburb : "",
    state: profile?.users?.state ? profile?.users?.state : "",
  };

  const validationSchema = Yup.object().shape({
    mobile: Yup.string().matches(
      /^(?:\+61|0)(?:(?:(?:2[1-9])|(?:3[0-9])|(?:4[1-9])|(?:5[0-9])|(?:6[1-9])|(?:7[0-9])|(?:8[1-9])|(?:9[0-9]))\d{7}|(?:2[1-9])\d{8})$/,
      "Invalid Australian Phone Number"
    ),
  });

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    const body = {
      first_name: values.first_name,
      last_name: values.last_name,
      mobile: values.mobile,
      email: values.email,
      address: address,
      postcode: postcode,
      suburb: suburb,
      state: state,
    };
    dispatch(fetchUpdateProfile(body, token));
    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
    }, 1000);
  };

  const handleVerifyPhone = () => {
    const body = {
      mobile_number: phoneNumber,
    };

    dispatch(fetchVerifyMobile(body, token));
  };

  useEffect(() => {
    setPhoneNumber(profile.user_mobile);
  }, [profile]);

  useEffect(() => {
    handleChangeAddress();
  }, [value]);

  useEffect(() => {
    message && toast.success(message);
    message && setShowOtpPopup(true);
    message && dispatch(removeProfilesData());
    setTimeout(() => {
      message && dispatch(fetchGetUserProfile(token));
    }, 100);
  }, [message]);

  useEffect(() => {
    errMessage && toast.success(errMessage);
    errMessage && dispatch(removeProfilesData());
    setTimeout(() => {
      errMessage && dispatch(fetchGetUserProfile(token));
    }, 100);
  }, [errMessage]);

  return (
    <div className="w-full border bg-white rounded-xl mt-10">
      <p className="font-medium p-3">Profile Picture</p>
      <hr className="w-full" />
      <div className="p-3 mt-5">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnMount
        >
          {(formik) => (
            <Form>
              <div className="w-full lg:flex items-center gap-x-5 justify-between">
                <div className="w-full">
                  <FormikControl
                    label="First Name"
                    control="input"
                    name="first_name"
                    placeholder="Enter Your First Name"
                  />
                </div>

                <div className="w-full mt-7 lg:mt-0">
                  <FormikControl
                    label="Last Name"
                    control="input"
                    name="last_name"
                    placeholder="Enter Your Last Name"
                  />
                </div>
              </div>
              <div className="w-full lg:flex items-center gap-x-5 justify-between">
                <div className="text-sm w-full mt-7">
                  <div className="w-full flex items-center gap-x-5 pb-2">
                    <label
                      htmlFor="mobile"
                      className="font-medium flex text-sm items-center justify-between w-full"
                    >
                      Phone Number
                      <ErrorMessage name="mobile" component={TextError} />
                    </label>

                    {profile.mobile_verified === 0 ||
                    profile.mobile_verified === "0" ? (
                      <h1
                        className="text-red-700 cursor-pointer bg-red-100 rounded-full md:py-1 text-[0.5rem] md:text-xs min-w-max px-3 md:px-4 flex items-center gap-x-1 md:gap-x-2"
                        onClick={() => {
                          handleVerifyPhone();
                        }}
                      >
                        <IoIosInformationCircleOutline />
                        <span>Not Verified </span>
                      </h1>
                    ) : (
                      <h1 className="text-green-700 bg-green-100 rounded-full md:py-1 text-[0.5rem] md:text-xs w-fit px-3 md:px-4 flex items-center gap-x-1 md:gap-x-2">
                        <FiCheck />
                        <span>Verified </span>
                      </h1>
                    )}
                  </div>

                  <Field
                    name="mobile"
                    className="border border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300"
                    placeholder="Enter Your Phone Number"
                    onChange={(e) => {
                      formik.setFieldValue("mobile", e.target.value);
                      setPhoneNumber(e.target.value);
                    }}
                  />
                </div>

                <div className="w-full mt-7">
                  <FormikControl
                    label="Email"
                    checked={true}
                    control="input"
                    name="email"
                    placeholder="Enter Your Email"
                    readOnly
                  />
                </div>
              </div>

              <div className="w-full mt-7">
                <label className="text-sm font-medium flex items-center justify-between">
                  Address
                </label>

                <GooglePlacesAutocomplete
                  textInputProps={{
                    placeholderTextColor: "#32a852",
                    returnKeyType: "search",
                  }}
                  autocompletionRequest={{
                    componentRestrictions: { country: ["au"] },
                  }}
                  selectProps={{
                    value,
                    onChange: setValue,
                    isMulti: false,
                    placeholder: profile.street_address,
                  }}
                />
              </div>

              <div className="flex justify-between items-center gap-x-10 mt-8">
                <Button
                  value="Save Changes"
                  type="submit"
                  className="border w-full text-sm rounded-2xl bg-primary cursor-pointer text-white font-medium py-4 text-center"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Details;
