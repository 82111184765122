import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  campaign: "",
  question: "",
  answers: [],
  msg: "",
  errMsg: "",
  message: "",
};

export const Campaign = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CAMPAIGN:
      return {
        ...state,
        isloading: false,
        success: true,
        campaign: action.payload.campaign,
        question: action.payload.question,
        answers: action.payload.answers,
      };

    case ActionTypes.CAMPAIGN_NOT_FOUND:
      return {
        ...state,
        isloading: false,
        success: true,
        msg: action.payload,
      };

    case ActionTypes.SUBMIT_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.SUBMIT_CAMPAIGN_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.REMOVE_CAMPAIGN_DATA:
      return {
        isloading: true,
        success: false,
        campaign: "",
        question: "",
        answers: [],
        msg: "",
        message: "",
        errMsg: "",
      };

    default:
      return state;
  }
};
