import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { FaMoneyBills } from "react-icons/fa6";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchGetSaveSearch, removeAdsData } from "../../Redux/Ads/action";
import {
  Loading,
  Pagination,
  SavedSearchCard,
  SinglePagnination,
} from "../../Component";

const SavedSearches = () => {
  let params = new URLSearchParams(document.location.search);
  let pg = params.get("page");
  const [page, setPage] = useState(pg ? Number(pg) : 1);
  const dispatch = useDispatch();
  const token = Cookies.get("token");
  const profile = useSelector((state) => state.profile.profile, shallowEqual);
  const loading = useSelector((state) => state.ads.isloading, shallowEqual);

  const saveSearch = useSelector((state) => state.ads.saveSearch, shallowEqual);

  const totalData = useSelector((state) => state.ads.totalData, shallowEqual);

  const totalPage = Math.ceil(totalData / 10);

  useEffect(() => {
    dispatch(fetchGetSaveSearch(page, token));
    return () => {
      dispatch(removeAdsData());
    };
  }, [page]);

  if (loading) {
    return (
      <div className="w-full h-full">
        <Loading />
      </div>
    );
  }
  return (
    <div className="w-full h-full mt-2 relative">
      <div className="w-full flex items-center gap-x-3">
        <h6 className="text-2xl font-semibold">Saved Searches</h6>
        <div className="w-fit py-1 px-3 flex items-center gap-x-3 border rounded-full bg-white">
          <FaMoneyBills className="text-xl text-primary" />
          <p className="font-semibold">{profile.classi_credit} Coins</p>
        </div>
      </div>

      {saveSearch && saveSearch.length ? (
        <div className="mt-10 w-full">
          <SavedSearchCard data={saveSearch} page={page} />
          <div className="mt-10">
            {totalPage > 5 ? (
              <Pagination
                setPage={setPage}
                page={page}
                totalData={totalData}
                totalPage={totalPage}
              />
            ) : (
              <SinglePagnination
                setPage={setPage}
                page={page}
                totalData={totalData}
                totalPage={totalPage}
              />
            )}
          </div>
        </div>
      ) : (
        <h1 className="mt-10 font-medium text-center">
          Sorry!! You haven't saved any of your searches
        </h1>
      )}
    </div>
  );
};

export default SavedSearches;
