import React, { useEffect, useState } from "react";
import { scrollToTop } from "../../Component/NavigateTop";
import {
  AdsCard,
  Categories,
  GridAdsCard,
  GridCategories,
  Head,
  HeroSection,
  Loading,
  Newsletter,
  ResgisterAds,
  Signup,
  Title,
} from "../../Component";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchGetAds, removeAdsData } from "../../Redux/Ads/action";
import { useNavigate } from "react-router-dom";
import {
  fetchGetCarBodyTypes,
  fetchGetBrands,
  removeCarData,
} from "../../Redux/Cars/action";
import {
  fetchAddtoWishlist,
  fetchRemoveWishlistByPostId,
  removeWishlistData,
} from "../../Redux/Wishlist/action";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { carHome } from "../../Assets/Assest";
import { fetchGetAllCategories } from "../../Redux/Category/action";

const Cars = () => {
  const [showCategories, setShowCategories] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [type, setType] = useState("");

  const data = localStorage.getItem("search-location");
  const parsedLocationData = JSON.parse(data);

  const [latitude, setLatitude] = useState(
    parsedLocationData ? parsedLocationData.latitude : ""
  );
  const [longitude, setLongitude] = useState(
    parsedLocationData ? parsedLocationData.longitude : ""
  );
  const [location, setLocation] = useState(
    parsedLocationData ? parsedLocationData.location : ""
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = Cookies.get("token");

  const loading = useSelector((state) => state.ads.isloading, shallowEqual);
  const carLoading = useSelector((state) => state.cars.isloading, shallowEqual);
  const catLoading = useSelector(
    (state) => state.categories.isloading,
    shallowEqual
  );
  const ads = useSelector((state) => state.ads.ads, shallowEqual);
  const bodyType = useSelector((state) => state.cars.bodyTypes, shallowEqual);
  const brands = useSelector((state) => state.cars.brands, shallowEqual);
  const filterData = useSelector((state) => state.ads.filters, shallowEqual);
  const message = useSelector((state) => state.wishlist.message, shallowEqual);
  const errMsg = useSelector((state) => state.wishlist.errMsg, shallowEqual);

  const handleAddToWishlist = (id) => {
    scrollToTop();
    const body = {
      post_id: id,
    };
    dispatch(fetchAddtoWishlist(body, token));
  };

  const handleRemoveWishlist = (id) => {
    scrollToTop();
    const body = {
      post_id: id,
    };
    dispatch(fetchRemoveWishlistByPostId(body, token));
  };

  const handleClickBodyType = (value) => {
    const filter = [
      {
        label: "body",
        value: value,
      },
    ];
    localStorage.setItem("search-data", JSON.stringify(filter));
    navigate(`/search-result?genre=cars&type=offering`);
  };

  const handleClickBrand = (value) => {
    const filter = [
      {
        label: "make",
        value: value,
      },
    ];
    localStorage.setItem("search-data", JSON.stringify(filter));
    navigate(`/search-result?genre=cars&type=offering`);
  };

  const handleSearch = () => {
    const data = searchData.filter((elem) => elem.label !== "type");

    if (data.length) {
      localStorage.setItem("search-data", JSON.stringify(data));
    }

    navigate(
      `/search-result?slug=automotive-1&genre=cars${
        type ? `&type=${type}` : `&type=offering`
      }${keyword ? `&key=${keyword}` : ""}`
    );
  };

  useEffect(() => {
    const data = searchData.find((val) => val.label === "type");
    setType(data?.value);
  }, [searchData]);

  useEffect(() => {
    localStorage.removeItem("search-data");
    scrollToTop();
    dispatch(fetchGetAds("cars", latitude, longitude, location, token));
    dispatch(fetchGetCarBodyTypes());
    dispatch(fetchGetBrands(1));
    dispatch(fetchGetAllCategories());
    return () => {
      dispatch(removeAdsData());
      dispatch(removeCarData());
    };
  }, []);

  useEffect(() => {
    const filter = setSearchData(() => {
      return [
        ...searchData,
        {
          label: "latitudes[]",
          value: latitude,
        },
        {
          label: "longitudes[]",
          value: longitude,
        },
        {
          label: "location",
          value: location,
        },
      ];
    });

    latitude !== "" &&
      longitude !== "" &&
      localStorage.setItem("search-data", JSON.stringify(filter));
  }, [latitude, longitude, location]);

  useEffect(() => {
    message && toast.success(message);
    message && dispatch(removeWishlistData());
    setTimeout(() => {
      message &&
        dispatch(fetchGetAds("cars", latitude, longitude, location, token));
    }, 100);
  }, [message]);

  useEffect(() => {
    errMsg && toast.error(errMsg);
    errMsg && dispatch(removeWishlistData());
    setTimeout(() => {
      errMsg &&
        dispatch(fetchGetAds("cars", latitude, longitude, location, token));
    }, 100);
  }, [errMsg]);

  if (loading || carLoading || catLoading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="pt-16 md:pt-24 relative">
      {showCategories ? (
        <div
          className="absolute w-full h-full bg-black opacity-10"
          onClick={() => {
            setShowCategories(false);
          }}
        ></div>
      ) : null}
      <Categories showCategories={showCategories} />

      <Head color="#BE3344" setShowCategories={setShowCategories} />
      <HeroSection
        pic={carHome}
        filterData={filterData}
        searchData={searchData}
        setSearchData={setSearchData}
        setKeyword={setKeyword}
        keyword={keyword}
        color="#BE3344"
        handleSearch={handleSearch}
        slug="cars"
        setLatitude={setLatitude}
        setLocation={setLocation}
        setLongitude={setLongitude}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
      />

      {token ? null : (
        <Signup
          color="#BE3344"
          bgColor="rgb(190 51 68 / var(--tw-bg-opacity))"
        />
      )}
      <div className="w-11/12 xl:w-3/4 mx-auto bg-white py-10">
        {bodyType && bodyType.length ? (
          <>
            <Title
              more={false}
              color="#BE3344"
              title1="Popular Make"
              title2="Explore Body Type"
            />
            <div className="mt-4 w-full grid grid-cols-3 md:grid-cols-4 gap-x-5">
              {bodyType.map((val) => {
                return (
                  <GridCategories
                    handleClick={() => handleClickBodyType(val.slug)}
                    key={val.id}
                    color="#BE3344"
                    image={val.image}
                    name={val.name}
                  />
                );
              })}
            </div>
          </>
        ) : null}

        {brands && brands.length ? (
          <div className="w-full mt-20">
            <Title
              more={false}
              color="#BE3344"
              title1="Popular Brands"
              title2="Explore Popular Brands"
            />

            <div className="mt-4 w-full grid grid-cols-3 md:grid-cols-4 gap-x-5">
              {brands.map((val) => {
                return (
                  <GridCategories
                    handleClick={() => handleClickBrand(val.make_id)}
                    data={val}
                    key={val.make_id}
                    color="#BE3344"
                    image={val.make_pic}
                    name={val.make_title}
                  />
                );
              })}
            </div>
          </div>
        ) : null}

        {token ? null : <ResgisterAds color="#BE3344" />}
        {ads && ads.length ? (
          <div className="mt-20">
            <Title
              more={true}
              color="#BE3344"
              title1="Our Products"
              title2="Explore Our Latest Ads"
              handleClick={() =>
                navigate(`/search-result?genre=cars&type=offering`)
              }
            />

            {ads.length > 3 ? (
              <AdsCard
                data={ads}
                handleAddToWishlist={handleAddToWishlist}
                handleRemoveWishlist={handleRemoveWishlist}
                slugs="cars"
                category="cars"
              />
            ) : (
              <div className="w-full grid grid-cols-1 pb-5 sm:grid-cols-2 lg:grid-cols-3 gap-x-3 xl:gap-x-6">
                {ads.map((val) => {
                  return (
                    <div key={val.post_id}>
                      <GridAdsCard
                        data={val}
                        handleAddToWishlist={handleAddToWishlist}
                        handleRemoveWishlist={handleRemoveWishlist}
                        slugs="cars"
                        category="cars"
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        ) : null}
      </div>
      <Newsletter
        color="#BE3344"
        bgColor="rgb(190 51 68 / var(--tw-bg-opacity))"
      />
    </div>
  );
};

export default Cars;
