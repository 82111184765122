import React, { useEffect, useState } from "react";
import {
  Breadcrum,
  Button,
  Categories,
  FormikControl,
  Head,
  Loading,
  LocationPopup,
  Map,
} from "../../Component";
import { scrollToTop } from "../../Component/NavigateTop";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchGetAllCategories } from "../../Redux/Category/action";
import {
  fetchSendAPiRequest,
  removeRequestData,
} from "../../Redux/Request/action";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const select = [
  {
    id: 1,
    key: "Friend",
    value: "Friend",
  },

  {
    id: 2,
    key: "Google",
    value: "Google",
  },

  {
    id: 3,
    key: "Social Media",
    value: "Social Media",
  },

  {
    id: 4,
    key: "Press Release",
    value: "Press Release",
  },

  {
    id: 5,
    key: "Others",
    value: "Others",
  },
];

const checkbox = [
  {
    id: 1,
    key: "Realestate",
    value: "Realestate",
  },

  {
    id: 2,
    key: "Job",
    value: "Job",
  },

  {
    id: 3,
    key: "Car",
    value: "Car",
  },
];

const ApiRequest = () => {
  const [showCategories, setShowCategories] = useState(false);
  const [showLocation, setShowLocation] = useState(false);

  const data = localStorage.getItem("search-location");
  const parsedLocationData = JSON.parse(data);

  const [latitude, setLatitude] = useState(
    parsedLocationData ? parsedLocationData.latitude : ""
  );
  const [longitude, setLongitude] = useState(
    parsedLocationData ? parsedLocationData.longitude : ""
  );
  const [location, setLocation] = useState(
    parsedLocationData ? parsedLocationData.location : ""
  );
  const [keyword, setKeyword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector(
    (state) => state.categories.isloading,
    shallowEqual
  );

  const message = useSelector((state) => state.request.message, shallowEqual);
  const errMessage = useSelector((state) => state.request.errMsg, shallowEqual);

  const handleClickSearch = () => {
    if (latitude && longitude) {
      const filter = [
        {
          label: "latitudes[]",
          value: latitude,
        },
        {
          label: "longitudes[]",
          value: longitude,
        },
        {
          label: "location",
          value: location,
        },
      ];
      localStorage.setItem("search-data", JSON.stringify(filter));
      navigate(`/search-result?genre=all&type=offering&key=${keyword}`);
    } else {
      navigate(`/search-result?genre=all&type=offering&key=${keyword}`);
    }
  };

  const mapContainerStyle = {
    width: "100%",
    height: "100%",
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
  };

  const initialValues = {
    company_name: "",
    contact_name: "",
    phone: "",
    email: "",
    message: "",
    api_for: [],
    referral: "Friend",
  };

  // check formik validation
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Required*"),
    company_name: Yup.string().required("Required*"),
    contact_name: Yup.string().required("Required*"),
    message: Yup.string().required("Required*"),
    api_for: Yup.array().required("Required*"),
    referral: Yup.string().required("Required*"),
    phone: Yup.string()
      .matches(/^[0-9]+$/, "Phone number must only contain digits")
      .min(9, "Phone number is too short")
      .max(11, "Phone number is too long")
      .required("Required*"),
  });

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    scrollToTop();
    dispatch(fetchSendAPiRequest(values));
    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
      onSubmitProps.resetForm({ value: "" });
    }, 1000);
  };

  useEffect(() => {
    message && toast.success(message);
    message && dispatch(removeRequestData());
  }, [message]);

  useEffect(() => {
    errMessage && toast.error(errMessage);
    errMessage && dispatch(removeRequestData());
  }, [errMessage]);

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetAllCategories());
  }, []);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="pt-16 md:pt-24 relative">
      {showCategories || showLocation ? (
        <div
          className="absolute w-full h-full bg-black opacity-10 z-30"
          onClick={() => {
            setShowCategories(false);
            setShowLocation(false);
          }}
        ></div>
      ) : null}

      {showLocation && (
        <div className="w-full absolute top-28">
          <LocationPopup
            setShowLocation={setShowLocation}
            setLatitude={setLatitude}
            setLongitude={setLongitude}
            setLocation={setLocation}
            location={location}
          />
        </div>
      )}

      <Head
        color="#2E8B4A"
        setShowCategories={setShowCategories}
        showLocation={showLocation}
        setShowLocation={setShowLocation}
        location={location}
        setKeyword={setKeyword}
        handleClickSearch={handleClickSearch}
      />

      <hr className="w-full mt-5" />

      <div className="w-11/12 xl:w-3/4 mx-auto mt-10">
        <Categories showCategories={showCategories} />
        <Breadcrum title="Home" subTitle="API Request" />

        <h6 className="font-semibold text-2xl mt-8">Developer API Request</h6>
      </div>

      <div className="w-full bg-gray-200 mt-16 grid grid-cols-12 gap-x-5 h-full">
        <div className="w-full h-[25rem] md:h-full col-span-12 md:col-span-6 bg-white border">
          <Map
            latitude="-33.868913"
            longitude="151.2063283"
            className={mapContainerStyle}
          />
        </div>
        <div className="w-full mt-6 md:mt-0 col-start-1 md:col-start-7 col-end-13 py-5 h-full">
          <div className="w-full lg:w-10/12 border rounded-xl bg-white  h-full p-7">
            <h6 className="font-semibold text-2xl">Get in Touch</h6>
            <p className="text-sm text-gray-500 mt-2">
              If you have any queries, send us a message. Our Friendly team
              would love to hear from you
            </p>

            <div className="mt-8">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                validateOnMount
              >
                {(formik) => (
                  <Form>
                    <div className="w-full">
                      <FormikControl
                        label="Company Name"
                        control="input"
                        name="company_name"
                        placeholder="Your company name"
                      />
                    </div>

                    <div className="w-full mt-5">
                      <FormikControl
                        label="Your Name"
                        control="input"
                        name="contact_name"
                        placeholder="Your full name"
                      />
                    </div>

                    <div className="w-full mt-5">
                      <FormikControl
                        label="Email"
                        control="input"
                        name="email"
                        placeholder="Your email address"
                      />
                    </div>

                    <div className="w-full mt-5">
                      <FormikControl
                        label="Phone Number"
                        control="input"
                        name="phone"
                        placeholder="Phone number"
                      />
                    </div>

                    <div className="w-full mt-5">
                      <FormikControl
                        label="How you know about us?"
                        options={select}
                        name="referral"
                        control="select"
                      />
                    </div>

                    <div className="w-full mt-5">
                      <FormikControl
                        label="API Request"
                        options={checkbox}
                        name="api_for"
                        control="checkbox"
                      />
                    </div>

                    <div className="w-full mt-5">
                      <FormikControl
                        label="Message"
                        name="message"
                        control="textarea"
                        placeholder="Tell us if you want to say anything"
                      />
                    </div>

                    <Button
                      value="Submit"
                      className="w-full bg-primary text-white mt-7 rounded-xl py-3"
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiRequest;
