import moment from "moment";
import React from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { shallowEqual, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ApprovedAds = () => {
  const navigate = useNavigate();
  const approvedAds = useSelector(
    (state) => state.dashboard.approvedAds,
    shallowEqual
  );
  return (
    <div className="w-full h-[26rem] overflow-auto border rounded-md bg-white">
      <div className="w-full sticky top-0">
        <div className="w-full flex items-center justify-between p-3  bg-white ">
          <h6 className="font-medium">Recently Approved Ads</h6>
          <p
            className="text-xs xl:text-sm text-primary flex items-center cursor-pointer hover:underline"
            onClick={() => navigate("/me/my-ads")}
          >
            View Details <MdKeyboardArrowRight className="text-lg" />
          </p>
        </div>
        <hr className="w-full" />
      </div>
      <div className="w-full p-3">
        {approvedAds &&
          approvedAds.map((val) => {
            return (
              <div
                className="w-full p-3 border rounded-md flex items-center justify-between mt-3"
                key={val.post_id}
              >
                <div className="w-full flex items-center gap-x-5">
                  <div className="w-16 h-16 overflow-hidden rounded-xl">
                    <img
                      src={val.image}
                      alt=""
                      className="w-full h-full object-fill"
                    />
                  </div>
                  <div>
                    <p className="font-medium text-sm truncate w-10/12">
                      {val.title}
                    </p>
                    <p className="text-xs text-gray-400 mt-1">
                      {moment.utc(val.posted_date).fromNow()}
                    </p>
                  </div>
                </div>

                <p className="flex items-center text-gray-500">
                  ${" "}
                  <span className="font-medium text-xl text-black">
                    {val.price}
                  </span>
                </p>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ApprovedAds;
