export const ADS_LOADING = "ADS_LOADING";
export const GET_SPOTLIGHT = "GET_SPOTLIGHT";
export const GET_ADS = "GET_ADS";
export const GET_FILTERED_ADS = "GET_FILTERED_ADS";
export const ADS_DETAIL = "ADS_DETAIL";
export const ADD_INSPECTION_SUCCESS = "ADD_INSPECTION_SUCCESS";
export const ADD_INSPECTION_FAIL = "ADD_INSPECTION_FAIL";
export const DELETE_INSPECTION_SUCCESS = "DELETE_INSPECTION_SUCCESS";
export const DELETE_INSPECTION_FAIL = "DELETE_INSPECTION_FAIL";
export const RESPOND_AD_SUCCESS = "RESPOND_AD_SUCCESS";
export const RESPOND_AD_FAIL = "RESPOND_AD_FAIL";
export const REPORT_ADS_SUCCESS = "REPORT_ADS_SUCCESS";
export const REPORT_ADS_FAIL = "REPORT_ADS_FAIL";
export const CHANGE_AD_STATUS_SUCCESS = "CHANGE_AD_STATUS_SUCCESS";
export const CHANGE_AD_STATUS_FAIL = "CHANGE_AD_STATUS_FAIL";
export const MAKE_AD_COMPLETED_SUCCESS = "MAKE_AD_COMPLETED_SUCCESS";
export const MAKE_AD_COMPLETED_FAIL = "MAKE_AD_COMPLETED_FAIL";
export const SAVE_SEARCH_SUCCESS = "SAVE_SEARCH_SUCCESS";
export const SAVE_SEARCH_FAIL = "SAVE_SEARCH_FAIL";
export const DELETE_SAVE_SEARCH_SUCCESS = "DELETE_SAVE_SEARCH_SUCCESS";
export const DELETE_SAVE_SEARCH_FAIL = "DELETE_SAVE_SEARCH_FAIL";
export const GET_SAVE_SEARCH = "GET_SAVE_SEARCH";
export const REVOME_ADS = "REMOVE_ADS";
