import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const removeCampaignData = () => ({
  type: ActionTypes.REMOVE_CAMPAIGN_DATA,
});

export const fetchGetCampaign = () => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/campaign    `)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_CAMPAIGN,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.CAMPAIGN_NOT_FOUND,
        payload: e?.response?.data,
      });
    });
};

export const fetchSubmitCampagin = (body) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/campaign`, body)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.SUBMIT_CAMPAIGN_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.SUBMIT_CAMPAIGN_FAIL,
        payload: e?.response?.data,
      });
    });
};
