import React, { useState } from "react";
import { Button } from "../../Component";
import { scrollToTop } from "../../Component/NavigateTop";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchGetFourthLevelCategories,
  fetchGetSecondLevelCategories,
  fetchGetThirdLevelCategories,
} from "../../Redux/Category/action";

const CategoryDetail = ({
  setToggle,
  secCat,
  setSecCat,
  firstCat,
  thriCat,
  setThriCat,
  frthCat,
  setFrthCat,
}) => {
  const [check, setCheck] = useState();
  const dispatch = useDispatch();

  const secCategory = useSelector(
    (state) => state.categories.secCategories,
    shallowEqual
  );

  const thridCategory = useSelector(
    (state) => state.categories.thirdCategories,
    shallowEqual
  );

  const fourthCategory = useSelector(
    (state) => state.categories.fourthCategories,
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchGetSecondLevelCategories(firstCat.id));
  }, []);

  return (
    <div className="w-full">
      <h6 className="font-semibold text-2xl">
        Choose <span className="text-primary">Category</span>
      </h6>
      <p className="text-base mt-2">
        Please select the appropriate sub catgory options based upon the ad you
        want to post
      </p>
      {thridCategory && thridCategory.length ? null : (
        <div className="border w-full  bg-white rounded-xl mt-10">
          <p className="font-semibold text-lg p-5">{firstCat.name}</p>
          <hr className="w-full" />
          <div className="p-5 w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-5">
            {secCategory &&
              secCategory.map((val) => {
                return (
                  <div
                    key={val.cat_id}
                    onClick={() => {
                      setCheck(val.has_child);
                      setSecCat({
                        id: val.cat_id,
                        name: val.cat_name,
                      });
                    }}
                    className={`${
                      secCat.id === val.cat_id
                        ? "border-primary bg-green-100"
                        : ""
                    } border rounded-lg mt-3 text-center py-3 cursor-pointer hover:border-primary`}
                  >
                    <p>{val.cat_name}</p>
                  </div>
                );
              })}
          </div>
          <div className="w-full flex justify-end pb-5 px-5">
            <Button
              value="Next"
              disable={secCat.id ? false : true}
              handleClick={() => {
                scrollToTop();
                if (check === 0) {
                  setToggle({
                    category: false,
                    detail: false,
                    form: true,
                  });
                } else {
                  dispatch(fetchGetThirdLevelCategories(secCat.id));
                  setCheck("");
                }
              }}
              className="border rounded-xl py-3 px-7 w-52 bg-primary text-white"
            />
          </div>
        </div>
      )}

      {fourthCategory && fourthCategory.length ? null : thridCategory &&
        thridCategory.length ? (
        <div className="border w-full  bg-white rounded-xl mt-10">
          <p className="font-semibold text-lg p-5">{secCat.name}</p>
          <hr className="w-full" />
          <div className="p-5 w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-5">
            {thridCategory &&
              thridCategory.map((val) => {
                return (
                  <div
                    key={val.cat_id}
                    onClick={() => {
                      setThriCat({
                        id: val.cat_id,
                        name: val.cat_name,
                      });
                      setCheck(val.has_child);
                    }}
                    className={`${
                      thriCat.id === val.cat_id
                        ? "border-primary bg-green-100"
                        : ""
                    } border rounded-lg mt-3 text-center py-3 cursor-pointer hover:border-primary`}
                  >
                    <p>{val.cat_name}</p>
                  </div>
                );
              })}
          </div>
          <div className="w-full flex justify-end pb-5 px-5">
            <Button
              value="Next"
              disable={thriCat.id ? false : true}
              handleClick={() => {
                scrollToTop();
                if (check === 0) {
                  setToggle({
                    category: false,
                    detail: false,
                    form: true,
                  });
                } else {
                  dispatch(fetchGetFourthLevelCategories(thriCat.id));
                  setCheck("");
                }
              }}
              className="border rounded-xl py-3 px-7 w-52 bg-primary text-white"
            />
          </div>
        </div>
      ) : null}

      {fourthCategory && fourthCategory.length ? (
        <div className="border w-full  bg-white rounded-xl mt-10">
          <p className="font-semibold text-lg p-5">{thriCat.name}</p>
          <hr className="w-full" />
          <div className="p-5 w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-5">
            {fourthCategory &&
              fourthCategory.map((val) => {
                return (
                  <div
                    key={val.cat_id}
                    onClick={() => {
                      setFrthCat({
                        id: val.cat_id,
                        name: val.cat_name,
                      });
                    }}
                    className={`${
                      frthCat.id === val.cat_id
                        ? "border-primary bg-green-100"
                        : ""
                    } border rounded-lg mt-3 text-center py-3 cursor-pointer hover:border-primary`}
                  >
                    <p>{val.cat_name}</p>
                  </div>
                );
              })}
          </div>

          <div className="w-full flex justify-end pb-5 px-5">
            <Button
              value="Next"
              disable={frthCat.id ? false : true}
              handleClick={() => {
                scrollToTop();
                setToggle({
                  category: false,
                  detail: false,
                  form: true,
                });
              }}
              className="border rounded-xl py-3 px-7 w-52 bg-primary text-white"
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default CategoryDetail;
