import React from "react";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { scrollToTop } from "../../Component/NavigateTop";
import { fetchGetAboutus, removeAboutus } from "../../Redux/Aboutus/action";
import {
  Breadcrum,
  Categories,
  Download,
  FeaturedCategory,
  Head,
  Loading,
  LocationPopup,
  Newsletter,
} from "../../Component";
import { about } from "../../Assets/Assest";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchGetAllCategories } from "../../Redux/Category/action";

const Aboutus = () => {
  const [showCategories, setShowCategories] = useState(false);
  const [showLocation, setShowLocation] = useState(false);

  const data = localStorage.getItem("search-location");
  const parsedLocationData = JSON.parse(data);

  const [latitude, setLatitude] = useState(
    parsedLocationData ? parsedLocationData.latitude : ""
  );
  const [longitude, setLongitude] = useState(
    parsedLocationData ? parsedLocationData.longitude : ""
  );
  const [location, setLocation] = useState(
    parsedLocationData ? parsedLocationData.location : ""
  );
  const [keyword, setKeyword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector((state) => state.aboutus.isloading, shallowEqual);
  const catLoading = useSelector(
    (state) => state.categories.isloading,
    shallowEqual
  );
  const aboutus = useSelector((state) => state.aboutus.aboutus, shallowEqual);

  const handleClickSearch = () => {
    if (latitude && longitude) {
      const filter = [
        {
          label: "latitudes[]",
          value: latitude,
        },
        {
          label: "longitudes[]",
          value: longitude,
        },
        {
          label: "location",
          value: location,
        },
      ];
      localStorage.setItem("search-data", JSON.stringify(filter));
      navigate(`/search-result?genre=all&type=offering&key=${keyword}`);
    } else {
      navigate(`/search-result?genre=all&type=offering&key=${keyword}`);
    }
  };

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetAboutus());
    dispatch(fetchGetAllCategories());
    return () => {
      dispatch(removeAboutus());
    };
  }, []);

  if (loading || catLoading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="pt-16 md:pt-24 relative">
      {showCategories || showLocation ? (
        <div
          className="absolute w-full h-full bg-black opacity-10 z-30"
          onClick={() => {
            setShowCategories(false);
            setShowLocation(false);
          }}
        ></div>
      ) : null}

      {showLocation && (
        <LocationPopup
          setShowLocation={setShowLocation}
          setLatitude={setLatitude}
          setLongitude={setLongitude}
          setLocation={setLocation}
          location={location}
        />
      )}

      <Categories showCategories={showCategories} />

      <Head
        setShowCategories={setShowCategories}
        color="#2E8B4A"
        showLocation={showLocation}
        setShowLocation={setShowLocation}
        location={location}
        setKeyword={setKeyword}
        handleClickSearch={handleClickSearch}
      />
      <hr className="w-full mt-7" />
      <div className="w-11/12 xl:w-3/4 mx-auto mt-10">
        <Breadcrum title="Home" subTitle="About Us" />
      </div>

      <div className="w-full h-[26rem] mt-10 relative">
        <img src={about} alt="" className="w-full h-full object-cover" />
        <p className="font-bold text-3xl absolute top-1/3 w-1/2 left-[10%] leading-10">
          <span className="text-primary">We Connect</span> Buyers and Sellers{" "}
          <br />
          with wide range of Products and Services
        </p>
      </div>
      <div className="w-11/12 xl:w-3/4 mx-auto mt-10">
        <h5 className="font-semibold text-2xl">About Us</h5>
        <p
          className=" tracking-wider leading-7 mt-3 about"
          dangerouslySetInnerHTML={{
            __html: aboutus,
          }}
        ></p>

        <FeaturedCategory />
        <Download />
      </div>

      <div className="mt-10">
        <Newsletter
          color="#2E8B4A"
          bgColor="rgb(46 139 74 / var(--tw-bg-opacity))"
        />
      </div>
    </div>
  );
};

export default Aboutus;
