import React from "react";
import { appStore, mobileIcon, playStore } from "../../Assets/Assest";

const Download = () => {
  return (
    <div className="w-full bg-primary mt-20 rounded-3xl border grid gap-x-5 grid-cols-12">
      <div className="col-span-12  lg:col-span-8 w-full h-full p-6 md:p-10">
        <p className="text-white text-base font-medium">Download the App?</p>

        <h3 className="font-semibold text-3xl text-white mt-8">
          Download the Classibazaar app today
        </h3>
        <p className="text-white text-base mt-5 tracking-wide">
          Get instant or daily emails and push notifications to be alerted about
          products that match what you're looking for!
        </p>
        <div className="flex items-center gap-x-2 lg:gap-x-10 mt-6 lg:mt-10">
          <a href="https://play.google.com/store/apps/details?id=com.classibazaarapp">
            <img
              src={playStore}
              alt="play store"
              className="w-28 h-8 md:w-36 md:h-9 lg:w-40 lg:h-12 xl:w-44 xl:h-14 cursor-pointer"
            />
          </a>

          <a href="https://apps.apple.com/au/app/classibazaar/id1496587117?ls=1">
            <img
              src={appStore}
              alt="app store"
              className="w-28 h-8 md:w-36 md:h-9 lg:w-40 lg:h-12 xl:w-44 xl:h-14 cursor-pointer"
            />
          </a>
        </div>
      </div>
      <div className="hidden lg:block col-start-8 md:col-start-9 col-end-13 h-full relative w-full">
        <img src={mobileIcon} className="absolute bottom-0" />
        {/* <img
          src={mobileIcon}
          alt=""
          className="absolute -top-10 md:-top-3 xl:-top-10 2xl:-top-14 left-0 2xl:left-10 w-full h-[400px] xl:h-[450px] object-fill md:object-fill 2xl:object-contain"
        /> */}
      </div>
    </div>
  );
};

export default Download;
