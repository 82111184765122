import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Button } from "../../Component";
import { useNavigate } from "react-router-dom";
import { classiBazarLogo } from "../../Assets/Assest";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import moment from "moment";

const ListingSearch = ({
  category,
  slugs,
  handleAddToWishlist,
  handleRemoveWishlist,
}) => {
  const navigate = useNavigate();
  const ads = useSelector((state) => state.ads.ads, shallowEqual);

  return (
    <div className="w-full h-full">
      {ads &&
        ads.map((data) => {
          return (
            <div
              className="mt-10 h-[22rem] w-full relative rounded-xl border cursor-pointer transition-all duration-300 hover:shadow-md flex gap-x-3"
              key={data.post_id}
              onClick={() => {
                navigate(`/ads-detail?i=${data.post_id}&name=${data.title}`);
              }}
            >
              {data.cat_name === "Jobs" || data.cat_name === "jobs" ? null : (
                <div className="w-2/5 h-[22rem] relative rounded-l-xl overflow-hidden">
                  {data?.images?.[0]?.thumbnail ? (
                    <img
                      src={data?.images?.[0]?.thumbnail}
                      alt="img"
                      className="w-full h-full object-fill"
                    />
                  ) : (
                    <img
                      src={classiBazarLogo}
                      alt="img"
                      className="w-full h-full object-contain"
                    />
                  )}

                  {data.logo === "1" ? (
                    <div className="w-14 h-12 rounded-full absolute left-2 top-1 overflow-hidden">
                      {data.logo_pic ? (
                        <img
                          src={data.logo_pic}
                          alt=""
                          className="w-full h-full object-cover"
                        />
                      ) : (
                        <img
                          src={classiBazarLogo}
                          alt=""
                          className="w-full h-full object-contain"
                        />
                      )}
                    </div>
                  ) : null}

                  {data.ad_status === "4" ? (
                    <div className="absolute bottom-5 text-white flex items-center justify-center gap-x-1 bg-red-500 right-10 md:right-5 py-1 pl-3 pr-3 rounded-lg">
                      <p className="font-medium capitalize">{data.status}</p>
                    </div>
                  ) : null}
                </div>
              )}

              <div
                className={`${
                  data.cat_name === "Jobs" || data.cat_name === "jobs"
                    ? "w-full"
                    : "w-3/5"
                } p-4 h-full`}
              >
                <div className="w-full flex items-center justify-between">
                  <div className="w-full flex items-center gap-x-3 flex-wrap mb-2">
                    {data.featured === "1" && (
                      <p className="w-fit bg-primary text-white py-1 px-2 rounded-md text-xs">
                        Featured
                      </p>
                    )}

                    {data.spoted === "1" && (
                      <p className="w-fit bg-jobPrimary text-white py-1 px-2 rounded-md text-xs">
                        Spotted
                      </p>
                    )}

                    {data.ad_status !== "4" && data.urgent === "1" ? (
                      <div className="w-fit bg-red-500 text-white py-1 px-2 rounded-md text-xs">
                        <p className="font-medium capitalize">Urgent</p>
                      </div>
                    ) : null}
                  </div>
                  {data.logo === "1" &&
                  (data.cat_name === "Jobs" || data.cat_name === "jobs") ? (
                    <div className="w-20 h-14">
                      <img
                        src={data.logo_pic}
                        alt=""
                        className="w-full h-full object-contain"
                      />
                    </div>
                  ) : null}
                </div>

                <h5
                  className={`font-medium text-sm md:text-base truncate
                      ${
                        category === "cars" || slugs === "cars"
                          ? " text-carPrimary"
                          : category === "motorcycles-scooters" ||
                            slugs === "motorcycles-scooters"
                          ? " text-carPrimary"
                          : category === "jobs" || slugs === "jobs"
                          ? " text-jobPrimary"
                          : category === "real-estate" ||
                            slugs === "real-estate"
                          ? "text-realestatePrimary"
                          : category === "pgflatmates" ||
                            slugs === "pgflatmates"
                          ? "text-pgFlatmatePrimary"
                          : " text-primary"
                      }`}
                >
                  {data.title}
                </h5>

                <p
                  className="text-sm text-gray-400 mt-3 truncates"
                  dangerouslySetInnerHTML={{ __html: data?.description }}
                ></p>

                <div className="w-full flex items-center justify-between mt-3">
                  {data.show_price === "Yes" ? (
                    <div className="w-full flex items-center gap-x-2">
                      <h4 className="text-textPrimary font-semibold text-base md:text-lg">
                        {data.price !== "0"
                          ? data.price_type !== "2"
                            ? `$${data.price} 
                `
                            : `$${data.price} - $${data.to_price}  
                `
                          : ""}
                      </h4>
                      {data.cat_name === "Jobs" ? (
                        <>
                          {data.show_price === "Yes" && data.price !== "0" ? (
                            <span>/</span>
                          ) : null}
                          <p className="text-sm truncate">{data.salary_type}</p>
                        </>
                      ) : null}
                    </div>
                  ) : null}

                  {data.ad_condition && (
                    <p className="text-xs bg-red-600 text-white rounded-md py-1 px-2">
                      {data.ad_condition}
                    </p>
                  )}
                </div>

                {data.cat_name === "Jobs" ? (
                  <div className="w-full flex items-center gap-x-5 mt-3">
                    <p className="w-fit py-1 px-5 bg-gray-200 text-center rounded-md text-sm">
                      {data.job_type}
                    </p>
                  </div>
                ) : null}

                {/* icons */}
                <div className="flex flex-wrap items-center gap-x-3 md:gap-x-5 mt-3">
                  {data.ads_data &&
                    data.ads_data.map((item, i) => {
                      return item.value === null ||
                        !item.value ||
                        item.value === "0" ||
                        item.value === " m sq." ||
                        item.label === "Agency" ? null : (
                        <div
                          className="flex items-center gap-x-2 py-2.5"
                          key={i}
                        >
                          <img
                            src={item.img}
                            alt="parking"
                            className="w-3 h-3 md:w-5 md:h-5"
                          />
                          <h6 className="text-sm">{item.value}</h6>
                        </div>
                      );
                    })}
                </div>

                <div className="w-full flex items-center justify-between">
                  <p className="text-textSecondary text-sm truncate">
                    {data.cat_name === "Jobs" || data.cat_name === "jobs"
                      ? data.address
                      : data.suburb
                      ? data.suburb + ","
                      : "" + data.state_name}
                  </p>
                  <div className="hover:bg-gray-100 rounded-full p-2">
                    {data.ad_status === "4" ? null : data.is_wishlist ? (
                      <AiFillHeart
                        size={27}
                        className="text-red-500"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveWishlist(data.post_id);
                        }}
                      />
                    ) : (
                      <AiOutlineHeart
                        size={27}
                        className="text-textSecondary"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleAddToWishlist(data.post_id);
                        }}
                      />
                    )}
                  </div>
                </div>
                <div
                  className={`${
                    data.cat_name === "Jobs" || data.cat_name === "jobs"
                      ? "w-[96%]"
                      : "w-[55%]"
                  } p-4 absolute bottom-1`}
                >
                  <hr className="w-full mt-2" />
                  <div className="w-full flex items-center justify-between mt-4">
                    <div className="w-full flex items-center flex-col">
                      <p className="text-sm w-full text-gray-400">
                        Posted by{" "}
                        <span className="font-medium text-black">
                          {data.sale_by}
                        </span>
                      </p>
                      <p className="text-sm w-full text-gray-400">
                        {moment.utc(data.posted_date).local().fromNow()}
                      </p>
                    </div>
                    <Button
                      value="View Details"
                      handleClick={() =>
                        navigate(
                          `/ads-detail?i=${data.post_id}&name=${data.title}`
                        )
                      }
                      className={`${
                        category === "cars" || slugs === "cars"
                          ? "border-carPrimary text-carPrimary"
                          : category === "motorcycles-scooters" ||
                            slugs === "motorcycles-scooters"
                          ? "border-carPrimary text-carPrimary"
                          : category === "jobs" || slugs === "jobs"
                          ? "border-jobPrimary text-jobPrimary"
                          : category === "real-estate" ||
                            slugs === "real-estate"
                          ? "border-realestatePrimary text-realestatePrimary"
                          : category === "pgflatmates" ||
                            slugs === "pgflatmates"
                          ? "border-pgFlatmatePrimary text-pgFlatmatePrimary"
                          : "border-primary text-primary"
                      } py-3 w-48 rounded-xl border`}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ListingSearch;
