import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Button, FormikControl } from "../../Component";
import * as Yup from "yup";
import Cookies from "js-cookie";
import { fetchRespondAds } from "../../Redux/Ads/action";
import { scrollToTop } from "../../Component/NavigateTop";
import { classiBazarLogo } from "../../Assets/Assest";
import { RiAttachment2 } from "react-icons/ri";
import { fetchSendFile, removeInboxData } from "../../Redux/Message/action";
import { toast } from "react-toastify";

const RespondAds = ({ setRespondAds, respondAds, postId, setInProgress }) => {
  const [image, setImage] = useState();
  const detail = useSelector((state) => state.ads.detail, shallowEqual);
  const profile = useSelector((state) => state.profile.profile, shallowEqual);
  const file = useSelector((state) => state.message.file, shallowEqual);
  const errMessage = useSelector((state) => state.message.errMsg, shallowEqual);

  const dispatch = useDispatch();
  const token = Cookies.get("token");

  const initialValues = {
    user_email: profile ? profile.user_email : "",
    message: respondAds.is_apply
      ? `I would like to apply for ${detail.title}`
      : respondAds.is_contact
      ? "I am interested in this product."
      : respondAds.is_offer
      ? `I am interested in ${detail.title}`
      : "",
    first_name: profile ? profile.first_name : "",
    last_name: profile ? profile.last_name : "",
    price: respondAds.is_offer ? detail.price : "",
    txt_filename: "",
  };

  const validationSchema = Yup.object().shape({
    user_email: Yup.string().required("Required*"),
    message: Yup.string().required("Required*"),
    first_name: Yup.string().required("Required*"),
    last_name: Yup.string().required("Required*"),
  });

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    scrollToTop();

    const body = {
      email: values.user_email,
      message: values.message,
      postid: postId,
      name: values.first_name + values.last_name,
      request_type: respondAds.is_offer ? 0 : 1,
      offer_price: values.price,
      txt_filename: file.src,
    };

    setInProgress(true);
    dispatch(fetchRespondAds(body, token));
    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
    }, 1000);
  };

  useEffect(() => {
    errMessage && toast.error(errMessage);
    errMessage && dispatch(removeInboxData());
  }, [errMessage]);

  return (
    <div className="w-11/12 md:w-3/4 lg:w-1/2 left-[4%] md:translate-x-[12%] lg:translate-x-1/2 top-[5%] border rounded-xl py-5 z-30 bg-white absolute">
      <div className="w-full justify-between px-5 flex items-center">
        <p className="text-base font-semibold">
          {respondAds.is_contact ? "Contact Us" : "Make An Offer"}
        </p>
        <div
          className="w-fit p-2 rounded-lg cursor-pointer hover:bg-gray-100"
          onClick={() => {
            setRespondAds({
              is_contact: false,
              is_offer: false,
              is_apply: false,
            });
          }}
        >
          <IoMdClose className="text-xl text-gray-600" />
        </div>
      </div>

      <hr className="w-full mt-5" />

      <div className="w-full mt-5 px-5 flex gap-x-5 h-full">
        <div className="w-32 h-28 rounded-xl overflow-hidden">
          {detail.images?.[0]?.image_name ? (
            <img
              src={detail.images?.[0]?.image_name}
              alt=""
              className="w-full h-full object-fill"
            />
          ) : (
            <img
              src={classiBazarLogo}
              alt=""
              className="w-full h-full object-fill"
            />
          )}
        </div>
        <div className="w-3/4">
          <p className="font-semibold text-lg truncate">{detail.title}</p>
          <p className="text-lg font-semibold">
            {" "}
            {detail.price !== "0"
              ? detail.price_type !== "2"
                ? `$${detail.price} 
                    `
                : `$${detail.price} - $${detail.to_price}  
                    `
              : ""}
          </p>
        </div>
      </div>

      <hr className="w-full mt-5" />
      <div className="w-full mt-5 px-5">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnMount
        >
          {(formik) => (
            <Form>
              <div className="w-full flex items-center gap-x-5">
                <div className="w-full">
                  <FormikControl
                    label="First Name"
                    control="input"
                    name="first_name"
                    placeholder="Your First Name"
                  />
                </div>

                <div className="w-full">
                  <FormikControl
                    label="Last Name"
                    control="input"
                    name="last_name"
                    placeholder="Your Last Name"
                  />
                </div>
              </div>
              <div className="w-full mt-5">
                <FormikControl
                  label="Email"
                  control="input"
                  name="user_email"
                  placeholder="Your Email"
                />
              </div>
              {respondAds.is_offer ? (
                <div className="w-full mt-5">
                  <FormikControl
                    label="What's your offer?"
                    control="input"
                    name="price"
                    type="number"
                    placeholder="$0"
                  />
                </div>
              ) : respondAds.is_apply ? (
                <div className="w-full mt-5">
                  <div className="w-full flex items-center justify-between">
                    <div className="w-full">
                      <p className="text-sm font-medium">Attach your CV</p>
                      <p className="text-xs mt-1 text-gray-600">
                        Note: Attach your CV for this job
                      </p>
                    </div>
                    <label className="w-fit p-3 flex items-center gap-x-2 rounded-full cursor-pointer">
                      <RiAttachment2 className="text-jobPrimary text-xl" />
                      <input
                        type="file"
                        className="w-full h-full border hidden"
                        onChange={(e) => {
                          setImage(e.target.files[0]);
                          const formData = new FormData();
                          formData.append("files", e.target.files[0]);
                          dispatch(fetchSendFile(formData, token));
                        }}
                      />
                      <p className="text-sm text-jobPrimary">Attach</p>
                    </label>
                  </div>
                  <p>{file && image?.name}</p>
                </div>
              ) : null}

              <div className="mt-5 w-full">
                <FormikControl
                  label="Tell us your interest in the deal"
                  control="textarea"
                  name="message"
                  placeholder="Here..."
                />
              </div>

              <Button
                value="Submit"
                type="submit"
                className="w-fit py-3 px-7 bg-primary text-white rounded-lg mt-5 text-sm"
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default RespondAds;
