import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const removeBlogsData = () => ({
  type: ActionTypes.REMOVE_BLOGS_DATA,
});

export const fetchGetBlogs = (keyword, category) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/blogs?keyword=${keyword}&catid=${category}`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_BLOGS,
        payload: data,
      });
    });
};

export const fetchGetBlogsDetail = (id) => async (dispatch) => {
  await axios.get(`${BaseUrl}/blog_detail/${id}`).then(({ data }) => {
    dispatch({
      type: ActionTypes.GET_BLOGS_DETAIL,
      payload: data,
    });
  });
};
